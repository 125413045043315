import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import "./fonts/Graphik-Light-Trial.otf";
import "./fonts/Graphik-Regular-Trial.otf";
import "./fonts/Graphik-Medium-Trial.otf";
import "./fonts/Graphik-Semibold-Trial.otf";
import "./fonts/Graphik-Bold-Trial.otf";

import ErrorBoundary from "./components/ErrorBoundary";
import Layout from "./layout/Layout";
import WithAxios from "./service/with-axios";
import AppStore from "./store";
import { AppThemeProvider } from "./theme";
import Routes from "./routes/Routes";

function App() {
  return (
    <ErrorBoundary name="App">
      <AppStore>
        <WithAxios>
          <AppThemeProvider>
            <BrowserRouter>
              <Layout>
                <Routes />
              </Layout>
            </BrowserRouter>
          </AppThemeProvider>
        </WithAxios>
      </AppStore>
    </ErrorBoundary>
  );
}

export default App;
