/* tslint:disable */
/* eslint-disable */
/**
 * Sms API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { StringMatch } from '../models';
import { SubscriptionPlanDto } from '../models';
/**
 * SubscriptionPlanApi - axios parameter creator
 * @export
 */
export const SubscriptionPlanApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansIdDelete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiSubscriptionPlansIdDelete.');
            }
            const localVarPath = `/api/subscription-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiSubscriptionPlansIdGet.');
            }
            const localVarPath = `/api/subscription-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansIdPut: async (id: string, body?: SubscriptionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling apiSubscriptionPlansIdPut.');
            }
            const localVarPath = `/api/subscription-plans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansPost: async (body?: SubscriptionPlanDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription-plans`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} [orderBy] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {{ [key: string]: string; }} [filter] 
         * @param {StringMatch} [stringMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSubscriptionPlansQueriesSearchGet: async (orderBy?: Array<string>, pageSize?: number, page?: number, filter?: { [key: string]: string; }, stringMatch?: StringMatch, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription-plans/queries/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (orderBy) {
                localVarQueryParameter['orderBy'] = orderBy;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }

            if (stringMatch !== undefined) {
                localVarQueryParameter['stringMatch'] = stringMatch;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionPlanApi - functional programming interface
 * @export
 */
export const SubscriptionPlanApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SubscriptionPlanDto>>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdDelete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansIdDelete(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SubscriptionPlanDto>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansIdGet(id, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdPut(id: string, body?: SubscriptionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SubscriptionPlanDto>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansIdPut(id, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansPost(body?: SubscriptionPlanDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<SubscriptionPlanDto>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {Array<string>} [orderBy] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {{ [key: string]: string; }} [filter] 
         * @param {StringMatch} [stringMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansQueriesSearchGet(orderBy?: Array<string>, pageSize?: number, page?: number, filter?: { [key: string]: string; }, stringMatch?: StringMatch, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<Array<SubscriptionPlanDto>>>> {
            const localVarAxiosArgs = await SubscriptionPlanApiAxiosParamCreator(configuration).apiSubscriptionPlansQueriesSearchGet(orderBy, pageSize, page, filter, stringMatch, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * SubscriptionPlanApi - factory interface
 * @export
 */
export const SubscriptionPlanApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansGet(options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SubscriptionPlanDto>>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdDelete(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansIdDelete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdGet(id: string, options?: AxiosRequestConfig): Promise<AxiosResponse<SubscriptionPlanDto>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansIdPut(id: string, body?: SubscriptionPlanDto, options?: AxiosRequestConfig): Promise<AxiosResponse<SubscriptionPlanDto>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansIdPut(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionPlanDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansPost(body?: SubscriptionPlanDto, options?: AxiosRequestConfig): Promise<AxiosResponse<SubscriptionPlanDto>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} [orderBy] 
         * @param {number} [pageSize] 
         * @param {number} [page] 
         * @param {{ [key: string]: string; }} [filter] 
         * @param {StringMatch} [stringMatch] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSubscriptionPlansQueriesSearchGet(orderBy?: Array<string>, pageSize?: number, page?: number, filter?: { [key: string]: string; }, stringMatch?: StringMatch, options?: AxiosRequestConfig): Promise<AxiosResponse<Array<SubscriptionPlanDto>>> {
            return SubscriptionPlanApiFp(configuration).apiSubscriptionPlansQueriesSearchGet(orderBy, pageSize, page, filter, stringMatch, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SubscriptionPlanApi - object-oriented interface
 * @export
 * @class SubscriptionPlanApi
 * @extends {BaseAPI}
 */
export class SubscriptionPlanApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansGet(options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SubscriptionPlanDto>>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansGet(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansIdDelete(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansIdDelete(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansIdGet(id: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<SubscriptionPlanDto>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} id 
     * @param {SubscriptionPlanDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansIdPut(id: string, body?: SubscriptionPlanDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<SubscriptionPlanDto>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansIdPut(id, body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {SubscriptionPlanDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansPost(body?: SubscriptionPlanDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<SubscriptionPlanDto>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {Array<string>} [orderBy] 
     * @param {number} [pageSize] 
     * @param {number} [page] 
     * @param {{ [key: string]: string; }} [filter] 
     * @param {StringMatch} [stringMatch] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionPlanApi
     */
    public async apiSubscriptionPlansQueriesSearchGet(orderBy?: Array<string>, pageSize?: number, page?: number, filter?: { [key: string]: string; }, stringMatch?: StringMatch, options?: AxiosRequestConfig) : Promise<AxiosResponse<Array<SubscriptionPlanDto>>> {
        return SubscriptionPlanApiFp(this.configuration).apiSubscriptionPlansQueriesSearchGet(orderBy, pageSize, page, filter, stringMatch, options).then((request) => request(this.axios, this.basePath));
    }
}
