export const IS_SERVER = typeof window === "undefined";
export const languageList = ["en", "fr", "de"];

export const GLOBAL_ROLES = {
  SuperAdmin: 1,
  Admin: 2,
  User: 3,
  Unverified: 4,
};

export type User = {
  userId: number;
  email: string;
  threadId: number;
  messages: {
    question: string;
    answer: string;
    timeStamp: string;
    companyId: number;
    isActive: boolean;
    updatedAt: string;
    updatedBy: number;
  }[];
};

export const transformData = (data: any[]): User[] => {
  const usersMap = new Map<number, User>();

  data.forEach((item) => {
    const {
      userId,
      email,
      threadId,
      question,
      answer,
      timeStamp,
      companyId,
      isActive,
      updatedAt,
      updatedBy,
    } = item;

    if (!usersMap.has(userId)) {
      usersMap.set(userId, {
        userId,
        email,
        threadId,
        messages: [],
      });
    }

    const user = usersMap.get(userId)!;
    user.messages.push({
      question,
      answer,
      timeStamp,
      companyId,
      isActive,
      updatedAt,
      updatedBy,
    });
  });

  console.log("Transformed users: ", Array.from(usersMap.values()));
  return Array.from(usersMap.values());
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
