import { FunctionComponent, PropsWithChildren } from 'react';
import { useIsAuthenticated } from '../hooks/auth';
import PrivateLayout from './PrivateLayout';
import PublicLayout from './PublicLayout';

const Layout: FunctionComponent<PropsWithChildren> = (props) => {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated ? <PrivateLayout {...props} /> : <PublicLayout {...props} />;
};

export default Layout;
