import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useAppStore } from "../../store";
import { useAppForm } from "../../utils/form";
import { useNavigate } from "react-router-dom";
// import { UserApi } from '../../service/swagger';
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  InputAdornment,
  Select,
  MenuItem,
  Skeleton,
  Typography,
  TextField,
  Container,
} from "@mui/material";
import { isValid } from "date-fns";
import {
  CountryIso2,
  usePhoneInput,
  defaultCountries,
  FlagImage,
  parseCountry,
} from "react-international-phone";

const VALIDATION_FORM_PROFILE = {
  name: {
    presence: true,
    format: {
      pattern: /^[a-zA-Z\s]+$/i,
      message: "must be appropriate",
    },
  },
  email: {
    presence: true,
    format: {
      pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i,
      message: "is not a valid email",
    },
  },
  phoneNumber: {
    presence: true,
  },
};

interface FormStateValues {
  name: string;
  companyName: string;
  email: string;
  phoneNumber: string;
}

const ProfileSkeleton = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      backgroundColor: "white",
      boxShadow: "none",
      marginLeft: "20px",
    }}
  >
    <Skeleton variant="text" width="30%" height={80} />
    <Skeleton variant="rectangular" width="30%" height={30} />
    <Skeleton variant="text" width="30%" height={80} />
    <Skeleton variant="rectangular" width="30%" height={30} />
    <Skeleton variant="text" width="30%" height={80} />
    <Skeleton variant="rectangular" width="30%" height={30} />
    <Skeleton variant="text" width="30%" height={80} />
    <Skeleton variant="rectangular" width="30%" height={30} />
    <Skeleton variant="rectangular" width="30%" height={20} />
  </Box>
);

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const Profile = () => {
  const [state, dispatch] = useAppStore();
  const navigate = useNavigate();
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [formState, setFormstate, onFieldChange, fieldGetError, fieldHasError] =
    useAppForm({
      validationSchema: VALIDATION_FORM_PROFILE,
      initialValues: {
        name: "",
        email: "",
        phoneNumber: "",
      } as FormStateValues,
    });
  const values = formState.values as FormStateValues;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   async function fetchUserProfile() {
  //     const response = await new UserApi().apiUsersGet();
  //     if (response.status === 401) {
  //       dispatch({ type: 'LOG_OUT' });
  //       navigate('/', { replace: true });
  //       return;
  //     }
  //     setFormstate({
  //       ...formState,
  //       values: {
  //         ...formState.values,
  //         name: response.data?.fullName,
  //         email: response.data?.email,
  //         phoneNumber: response.data?.phoneNumber
  //       },
  //     });
  //   }

  //   fetchUserProfile();
  // }, []);

  // const handleUpdateProfile = useCallback(
  //   async (event: SyntheticEvent) => {
  //     try {
  //       setFetchLoading(true);
  //       const updatedUser = (await new UserApi().apiUsersPut({ name: values.name })).data;
  //       setFormstate({
  //         ...formState,
  //         values: {
  //           ...formState.values,
  //           name: updatedUser?.fullName,
  //         },
  //       });
  //     } catch (e: any) {
  //       console.log(e);
  //     } finally {
  //       setFetchLoading(false);
  //     }
  //   },
  //   [formState, setFormstate, values.name]
  // );

  const commonTextFieldStyles = {
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#5b3c93",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "black",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#5b3c93",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "10px", // Apply border radius to the TextField
    },
  };

  const onEditProfile = () => {
    // Add your profile editing logic here
  };

  const [country, setCountry] = useState<CountryIso2>("pk");
  const [hasInteracted, setHasInteracted] = useState(false);

  const {
    inputValue,
    handlePhoneValueChange,
    inputRef,
    setCountry: setPhoneCountry,
  } = usePhoneInput({
    defaultCountry: country,
    value: "",
    countries: defaultCountries,
    onChange: (data) => {
      if (data.phone && hasInteracted) {
        setFormstate({
          ...formState,
          values: {
            ...formState.values,
            phoneNumber: data.phone,
          },
        });
      }
    },
  });

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fafafa",
        }}
      >
        {/* <Toolbar sx={{ minHeight: 48 }}>
          <BackButton />
          <Typography fontSize="22px" fontWeight="bold" marginLeft="20px">
            User Profile
          </Typography>
        </Toolbar> */}
      </Box>
      {loading ? (
        <ProfileSkeleton />
      ) : (
        <form onSubmit={onEditProfile}>
          <Box sx={{ width: "100%", marginLeft: "20px", mb: 2 }}>
            <Typography fontSize="15px" fontWeight="bold" mb={0.5}>
              Name
            </Typography>
            <Typography fontSize="12px" fontWeight="normal" mb={1}>
              The name associated with this account
            </Typography>
            <Box sx={{ width: "30%" }}>
              <TextField
                name="name"
                onChange={onFieldChange}
                error={fieldHasError("name")}
                helperText={fieldGetError("name")}
                size="small"
                fullWidth
                value={values.name}
                sx={commonTextFieldStyles}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", marginLeft: "20px", mb: 2 }}>
            <Typography fontSize="15px" fontWeight="bold" mb={0.5}>
              Email address
            </Typography>
            <Typography fontSize="12px" fontWeight="normal" mb={1}>
              The email address associated with this account
            </Typography>
            <Box sx={{ width: "30%" }}>
              <TextField
                name="email"
                onChange={onFieldChange}
                error={fieldHasError("email")}
                helperText={fieldGetError("email")}
                size="small"
                fullWidth
                disabled
                value={values.email}
                sx={commonTextFieldStyles}
              />
            </Box>
          </Box>
          <Box sx={{ width: "100%", marginLeft: "20px", mb: 2 }}>
            <Typography fontSize="15px" fontWeight="bold" mb={0.5}>
              Phone number
            </Typography>
            <Typography fontSize="12px" fontWeight="normal" mb={1}>
              The phone number associated with this account
            </Typography>
            <Box sx={{ width: "30%" }}>
              <TextField
                name="phoneNumber"
                variant="outlined"
                color="primary"
                placeholder="Phone number"
                value={values.phoneNumber}
                disabled
                fullWidth
                size="small"
                error={hasInteracted && !isValid}
                onChange={handlePhoneValueChange}
                helperText={
                  hasInteracted &&
                  !isValid &&
                  inputValue.trim() !== "" && (
                    <Typography fontSize="12px" color="#d32f2f">
                      Phone number is invalid
                    </Typography>
                  )
                }
                type="tel"
                inputRef={inputRef}
                onKeyDown={(e: any) =>
                  e.key === "+" ||
                  /^[0-9]$/.test(e.key) ||
                  e.key === "Backspace"
                    ? setHasInteracted(true)
                    : e.preventDefault()
                }
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: hasInteracted && !isValid ? "#d32f2f" : "#5b3c93",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "black",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      borderColor:
                        hasInteracted && !isValid ? "#d32f2f" : "#5b3c93",
                    },
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{ marginRight: "2px", marginLeft: "-8px" }}
                    >
                      <Select
                        disabled
                        MenuProps={{
                          style: {
                            height: "300px",
                            width: "342px",
                            top: "10px",
                            left: "-34px",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        sx={{
                          width: "max-content",
                          // Remove default outline (display only on focus)
                          fieldset: {
                            display: "none",
                          },
                          '&.Mui-focused:has(div[aria-expanded="false"])': {
                            fieldset: {
                              display: "block",
                            },
                          },
                          // Update default spacing
                          ".MuiSelect-select": {
                            padding: "8px",
                            paddingRight: "24px !important",
                          },
                          svg: {
                            right: 0,
                          },
                        }}
                        value={country}
                        onChange={(e) => {
                          setCountry(e.target.value as CountryIso2);
                          setPhoneCountry(e.target.value as CountryIso2); // Update the phone input country separately
                          setFormstate({
                            ...formState,
                            values: {
                              ...formState.values,
                              phoneNumber: "", // Reset phone number when the country changes
                            },
                          });
                        }}
                        renderValue={(value) => (
                          <FlagImage iso2={value} style={{ display: "flex" }} />
                        )}
                      >
                        {defaultCountries.map((c) => {
                          const country = parseCountry(c);
                          return (
                            <MenuItem key={country.iso2} value={country.iso2}>
                              <FlagImage
                                iso2={country.iso2}
                                style={{ marginRight: "8px" }}
                              />
                              <Typography marginRight="8px">
                                {country.name}
                              </Typography>
                              <Typography color="gray">
                                +{country.dialCode}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" sx={{ width: "30%" }}>
            <LoadingButton
              variant="contained"
              sx={{
                backgroundColor: "#5b3c93",
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(91, 60, 147, 0.9)",
                },
                mr: 1,
                ml: "20px", 
              }}
              disabled={!formState.isValid}
              loading={loading}
              type="submit"
              // onClick={handleUpdateProfile}
            >
              <Typography fontSize="14px" sx={{ textTransform: "none" }}>
                Save
              </Typography>
            </LoadingButton>
            <Button
              sx={{
                backgroundColor: "lightGray",
                color: "black",
                "&:hover": {
                  backgroundColor: "rgba(211, 211, 211, 0.8)",
                },
                mr: 1,
              }}
              onClick={() => navigate(-1)}
            >
              <Typography fontSize="14px" sx={{ textTransform: "none" }}>
                Cancel
              </Typography>
            </Button>
          </Box>
        </form>
      )}
    </Container>
  );
};

export default Profile;
