import { Box, Container, Typography, Divider, useTheme, useMediaQuery } from "@mui/material";
import { useState, useEffect } from "react";

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const words = [
    "EXTENSIONS",
    "CONVERSIONS",
    "NEW BUILD",
    "CHANGE OF USE",
    "DESIGN & \nACCESS STATEMENT",
    "FIRE SAFETY",
    "HMO"
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex(
        (prevIndex: number) => (prevIndex + 1) % words.length
      );
    }, 2000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            mt: 20,
            mb: 45,
          }}
        >
          <Typography
            variant={isMobile ? "h5" : "h3"}
            fontFamily="Medium"
            color="#3f301d"
            textAlign="center"
          >
            {"GPT RESEARCH ON "}
            <span
              style={{
                color: "#696969",
              }}
            >
              {
                "UK PERMITTED DEVELOPMENT / PLANNING PERMISSION / BUILDING REGULATIONS"
              }
            </span>
          </Typography>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            style={{
              fontFamily: "Medium",
              color: "#3f301d",
              whiteSpace: !isMobile ? "none" : "pre-line",
              textAlign: !isMobile ? "inherit" : "center"
            }}
          >
            {words[currentWordIndex]}
          </Typography>
        </Box>
      </Box>
      <Divider style={{ backgroundColor: "gray" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "center",
          mt: 5,
          p: 5,
          border: "1px solid grey",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: isMobile ? "center" : "flex-start",
            justifyContent: "center",
            flexDirection: "column",
            mr: isMobile ? 0 : 3,
          }}
        >
          <Typography
            variant={isMobile ? "h6" : "h5"}
            sx={{
              fontFamily: "Regular",
              color: "#3f301d",
            }}
          >
            {"How does it work?"}
          </Typography>
          <Typography
            sx={{
              fontFamily: "Regular",
              color: "#696969",
              mt: 3,
              whiteSpace: "pre-line",
              textAlign: !isMobile ? "start" : "center"
            }}
          >
            {
              "We bring together a comprehensive collection of essential policies, guidelines, documents, and legislation, combined with reliable databases, using ChatGPT. This allows us to provide prompt answers to assist you with Permitted development, Planning Permission, and Building Regulations in the U.K. Whether you are an architect, planner, technologist, or homeowner, you can quickly find the information that is important to you in a matter of seconds."
            }
          </Typography>
          <Typography
            sx={{
              fontFamily: "Bold",
              color: "#696969",
              mt: 3,
              textAlign: !isMobile ? "start" : "center"
            }}
          >
            {
              "Find the answer quickly and easily. Optimise your time for what's important."
            }
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center", // Align content to the start (left)
              flexDirection: "column",
              mt: 5,
            }}
          >
            <Typography
              variant={isMobile ? "h6" : "h5"}
              sx={{
                fontFamily: "Regular",
                color: "#3f301d",
                textAlign: !isMobile ? "start" : "center"
              }}
            >
              {"What makes us different from ChatGPT"}
            </Typography>
            <Typography
              sx={{
                fontFamily: "Regular",
                color: "#696969",
                mt: 3,
                whiteSpace: "pre-line",
                textAlign: !isMobile ? "start" : "center"
              }}
            >
              {
                "Unlike ChatGPT, which provides limited answers due to OpenAI's lack of access to policies, regulations, and restricted documents, KunArchGpt offers a solution that delivers quick answers with relevant details. With KunArchGpt, you can consider the laws and regulations while planning for permissions and permitted development, ensuring a more comprehensive understanding of the subject matter."
              }
            </Typography>
            <Typography
              sx={{
                fontFamily: "Bold",
                color: "#696969",
                whiteSpace: "pre-line",
                mt: 3,
                mb: isMobile ? 3 : 0,
                textAlign: !isMobile ? "start" : "center"
              }}
            >
              {
                "KunarchGPT gives you precise answers based on specific UK regulations. Includes references and links to reference materials."
              }
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexDirection: "row",
          }}
        >
          <img
            alt="KunarchGPT"
            src="/assets/images/arcgpt4.png"
            style={{
              width: isMobile ? "300px" : "600px", // Set the width of the image
              height: "auto", // Maintain aspect ratio
              borderRadius: "10px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              marginRight: "10px", // Add margin to the right of the first image
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          mt: 5,
          p: 5,
          border: "1px solid grey",
          borderRadius: "10px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Regular",
            fontSize: isMobile ? "24px" : "30px",
            color: "#696969",
            whiteSpace: "pre-line",
            textAlign: "center"
          }}
        >
          {isMobile ? "Try it for free and share \nyour experience !" : "Try it for free and share your experience !"}
        </Typography>
      </Box>
    </Container>
  );
};

export default LandingPage;
