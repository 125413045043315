import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../../service/swagger/base';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Divider,
  TextField,
  CardHeader,
  Box,
  Card,
  Dialog,
  DialogContent,
  Paper,
  CardContent,
  Link,
  Stack,
  Avatar,
  IconButton,
  Popover,
  InputAdornment,
  Tooltip,
  MenuItem,
  Select,
  CircularProgress,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  Add,
  Google,
  Login,
  Microsoft,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useEventLogout } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import { GLOBAL_ROLES } from "../../utils";
import { AppDropdown } from "../../components/AppDropdown";
import { useAppForm } from "../../utils/form";
import { LoadingButton } from "@mui/lab";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { PhoneNumberUtil } from "google-libphonenumber";
import "react-international-phone/style.css";
import {
  CountryIso2,
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import { AuthenticationApi } from "../../service/swagger/apis/authentication-api";
import axios from "axios";
import ArcGPTService from "../../service/ArcGPTService";

const VALIDATION_FORM_SIGN_IN = {
  email: {
    presence: true,
    format: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "entered is invalid",
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      message: "must be minimum 8 characters",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      message: "must include numbers and special characters",
    },
  },
};

const VALIDATION_FORM_SIGN_UP = {
  email: {
    presence: true,
    format: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "entered is invalid",
    },
  },
  password: {
    presence: true,
    length: {
      minimum: 8,
      message: "must be minimum 8 characters",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      message: "must include numbers and special characters",
    },
  },
  confirmPassword: {
    presence: true,
    equality: {
      attribute: "password",
      message: "and New Password do not match.",
    },
  },
  username: {
    presence: true,
    format: {
      pattern: /^[^\s]+$/,
      message: "entered is invalid",
    },
  },
  phoneNumber: {
    presence: true,
  },
};

const VALIDATION_FORM_FP = {
  email: {
    presence: true,
    format: {
      pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: "entered is invalid",
    },
  },
};

interface FormStateSignInValues {
  email: string;
  password: string;
}

interface FormStateSignUpValues {
  email: string;
  password: string;
  confirmPassword: string;
  username: string;
  phoneNumber: "";
}

interface FormStateFPValues {
  email: string;
}

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PublicNavbar = () => {
  const [state, dispatch] = useAppStore();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [
    formSignInState,
    setFormSignInstate,
    onFieldSignInChange,
    fieldSignInGetError,
    fieldSignInHasError,
  ] = useAppForm({
    validationSchema: VALIDATION_FORM_SIGN_IN,
    initialValues: {
      email: "",
      password: "",
    } as FormStateSignInValues,
  });
  const [
    formSignUpState,
    setFormSignUpstate,
    onFieldSignUpChange,
    fieldSignUpGetError,
    fieldSignUpHasError,
  ] = useAppForm({
    validationSchema: VALIDATION_FORM_SIGN_UP,
    initialValues: {
      email: "",
      password: "",
      username: "",
      phoneNumber: "",
    } as FormStateSignInValues,
  });
  const [
    formFPState,
    setFormFPstate,
    onFieldFPChange,
    fieldFPGetError,
    fieldFPHasError,
  ] = useAppForm({
    validationSchema: VALIDATION_FORM_FP,
    initialValues: {
      email: "",
    } as FormStateFPValues,
  });
  const signInValues = formSignInState.values as FormStateSignInValues;
  const signUpValues = formSignUpState.values as FormStateSignUpValues;
  const fpValues = formFPState.values as FormStateFPValues;
  const [fullName] = useState("User");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onLogout = useEventLogout();
  const [loginDialogOpen, setLoginDialogOpen] = useState(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isForgotPass, setIsForgotPass] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isValid = isPhoneValid(signUpValues.phoneNumber);
  const [loading, setLoading] = useState<boolean>(false);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    setAnchorEl(null);
    setIsLogin(true);
    setLoginDialogOpen(true);
  };

  const handleLoginDialogClose = () => {
    setLoginDialogOpen(false);
    setFormSignInstate({
      values: {
        email: "",
        password: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
    setFormSignUpstate({
      values: {
        email: "",
        password: "",
        confirmPassword: "",
        username: "",
        phoneNumber: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
    setFormFPstate({
      values: {
        email: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
  };

  const handleShowPassword = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleShowConfirmClick = useCallback(() => {
    setShowConfirmPassword((oldValue) => !oldValue);
  }, []);

  const handleShowSignin = () => {
    setIsSignUp(false);
    setIsLogin(true);
    setIsForgotPass(false);
    setFormSignUpstate({
      values: {
        email: "",
        password: "",
        confirmPassword: "",
        username: "",
        phoneNumber: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
    setFormFPstate({
      values: {
        email: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
  };

  const handleShowSignUp = () => {
    setIsSignUp(true);
    setIsLogin(false);
    setIsForgotPass(false);
    setFormSignInstate({
      values: {
        email: "",
        password: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
    setFormFPstate({
      values: {
        email: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
  };

  const handleShowForgotPass = () => {
    setIsSignUp(false);
    setIsLogin(false);
    setIsForgotPass(true);
    setFormSignInstate({
      values: {
        email: "",
        password: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
    setFormSignUpstate({
      values: {
        email: "",
        password: "",
        confirmPassword: "",
        username: "",
        phoneNumber: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
  };

  // add action to an individual snackbar
  const action = (
    <>
      <button
        style={{
          color: "white",
          fontFamily: "Medium",
          fontWeight: "500",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          closeSnackbar();
        }}
      >
        Dismiss
      </button>
    </>
  );

  const handleOpenChat = () => {
    handleShowSignin();
    setLoginDialogOpen(true);
    enqueueSnackbar("You need to Login to use this feature.", {
      action,
      variant: "success",
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
  };

  const [country, setCountry] = useState<CountryIso2>("pk");
  const [hasInteracted, setHasInteracted] = useState(false);

  const {
    inputValue,
    handlePhoneValueChange,
    inputRef,
    setCountry: setPhoneCountry,
  } = usePhoneInput({
    defaultCountry: country,
    value: "",
    countries: defaultCountries,
    onChange: (data) => {
      if (data.phone && hasInteracted) {
        setFormSignUpstate({
          ...formSignUpState,
          values: {
            ...formSignUpState.values,
            phoneNumber: data.phone,
          },
        });
      }
    },
  });

  const handlePhoneInputFocus = () => {
    setHasInteracted(true);
  };

  const handleSignup = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      try {
        setLoading(true);
        const response = await new AuthenticationApi().apiAuthRegisterPost({
          email: signUpValues.email,
          userName: signUpValues.username,
          phoneNo: signUpValues.phoneNumber,
          password: signUpValues.password,
          confirmPassword: signUpValues.confirmPassword,
        });
        console.log("Response: ", response);
        if (axios.isAxiosError(response)) {
          enqueueSnackbar(response.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          handleLoginDialogClose();
          enqueueSnackbar(
            "Sign up successfull! A verification email has been sent to your account.",
            {
              action,
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [signUpValues, enqueueSnackbar]
  );

  const handleSignIn = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      try {
        setLoading(true);
        const response = await new ArcGPTService().login(
          signInValues.email,
          signInValues.password
        );

        dispatch({
          type: "LOG_IN",
          loginData: response,
          expirationDate: response.expirationDate,
        });
        navigate("/", { replace: true });
        handleLoginDialogClose();
        enqueueSnackbar("You have successfully logged in.", {
          action,
          variant: "success",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [signInValues, dispatch, navigate, enqueueSnackbar]
  );

  const handleForgotPasswordSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      try {
        setLoading(true);
        const response =
          await new AuthenticationApi().apiAuthForgotPasswordRequestPost(
            fpValues.email
          );
        if (axios.isAxiosError(response)) {
          enqueueSnackbar(response.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          handleLoginDialogClose();
          enqueueSnackbar(
            "A password reset link has been sent to your account.",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [fpValues, enqueueSnackbar]
  );

  const handleGoogleSignin = async () => {
    try {
      setLoading(true);
      const returnUrl = encodeURIComponent("redirection");
      window.location.href = BASE_PATH +`/api/auth/google-login?provider=Google&returnUrl=${returnUrl}`;
      // const width = window.screen.width;
      // const height = window.screen.height;
      // const authWindow = window.open('http://localhost:5054/api/auth/google-login?provider=Google&returnUrl=abc.com', '_blank', `width=${width},height=${height}`);
      // const response = await new AuthenticationApi().apiAuthGoogleLoginGet(
      //   "Google",
      //   "http://localhost:3000/",
      // );
      // if (axios.isAxiosError(response)) {
      //   enqueueSnackbar(response.response?.data.errors[0], {
      //     variant: "error",
      //     anchorOrigin: { vertical: "bottom", horizontal: "center" },
      //   });
      // } else {
      //   enqueueSnackbar("You have successfully logged in", {
      //     action,
      //     variant: "success",
      //     anchorOrigin: { vertical: "bottom", horizontal: "center" },
      //   });
      // }
    } catch (e: any) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        minHeight: 45,
        backgroundColor: "#fafafa",
      }}
    >
      <Toolbar
        sx={{
          minHeight: 45,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton
          onClick={() => {
            navigate("/", { replace: true });
          }}
          disableFocusRipple
          disableRipple
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <img
              alt="ArcGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: isMobile ? "75px" : "200px",
                height: isMobile ? "75px" : "200px",
              }}
            />
          </Box>
        </IconButton>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          marginLeft="auto"
          alignItems="center"
        >
          {!isMobile ? (
            <Button
              onClick={handleOpenChat}
              disableElevation
              variant="contained"
              sx={{
                fontFamily: "Light",
                backgroundColor: "#fafafa",
                padding: "5px 5px",
                mr: "20px",
                ml: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#3f301d",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiTypography-root": {
                    color: "white",
                  },
                },
              }}
            >
              <Add
                sx={{
                  mr: "5px",
                  color: "grey",
                }}
              />
              <Typography
                fontFamily="Light"
                color="grey"
                sx={{
                  textTransform: "none",
                  mr: "5px",
                }}
              >
                NEW CHAT
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={handleOpenChat}
              disableElevation
              variant="contained"
              sx={{
                fontFamily: "Light",
                padding: "5px 5px",
                mr: "20px",
                ml: "20px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#3f301d",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiTypography-root": {
                  color: "white",
                },
              }}
            >
              <Add
                sx={{
                  mr: "5px",
                  color: "grey",
                }}
              />
              <Typography
                fontFamily="Light"
                color="grey"
                sx={{
                  textTransform: "none",
                  mr: "5px",
                }}
              >
                CHAT
              </Typography>
            </Button>
          )}
          <IconButton
            onClick={handleProfileClick}
            disableFocusRipple
            disableRipple
          >
            <Avatar sx={{ bgcolor: "#cacaca", width: 42, height: 42 }}>
              {fullName
                .split(" ")
                .slice(0, 2)
                .map((word: string) => word[0].toUpperCase())
                .join("")}
            </Avatar>
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box width="200px" p={1}>
              <Button
                onClick={handleLogin}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Login fontSize="medium" sx={{ color: "#696969", mr: 1 }} />
                <Typography
                  textTransform="none"
                  variant="body2"
                  fontFamily="Regular"
                  sx={{ color: "#696969" }}
                >
                  Login
                </Typography>
              </Button>
            </Box>
          </Popover>
        </Box>
      </Toolbar>
      {/* Login Dialog */}
      <Dialog
        open={loginDialogOpen}
        onClose={handleLoginDialogClose}
        maxWidth={isMobile ? "sm" : "lg"}
      >
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Circular progress */}
            <CircularProgress sx={{ color: "#ffffff" }} />
          </div>
        )}
        <Paper sx={{ backgroundColor: "transparent", overflow: "auto" }}>
          {isLogin ? (
            <DialogContent sx={{ display: "flex", alignItems: "center" }}>
              {isMobile ? (
                <Box />
              ) : (
                <Box sx={{ width: "50%", pr: 2 }}>
                  {/* Image on the left */}
                  <img
                    src="/assets/images/authbg.jpg"
                    alt="Authentication Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ width: isMobile ? "100%" : "50%" }}
              >
                {/* Login form on the right */}
                <Card elevation={0}>
                  <CardHeader title="SIGN IN TO KUNARCHGPT" titleTypographyProps={{fontSize: isMobile ? "18px" : "24px", textAlign: "center"}} />
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ width: "100%", mt: 2 }}
                    >
                      <Button
                        fullWidth
                        onClick={() =>
                          console.log("Sign In with Microsoft clicked")
                        }
                        color="primary"
                        sx={{
                          backgroundColor: "#f6f6f6",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="flex-start"
                        >
                          <Microsoft fontSize="small" sx={{ color: "black" }} />
                          <Typography
                            color="black"
                            textTransform="none"
                            sx={{ flexGrow: 1, textAlign: "center" }}
                          >
                            Sign in with Microsoft
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        fullWidth
                        onClick={handleGoogleSignin}
                        color="primary"
                        sx={{
                          mt: 2,
                          backgroundColor: "#f6f6f6",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="flex-start"
                        >
                          <Google fontSize="small" sx={{ color: "black" }} />
                          <Typography
                            color="black"
                            textTransform="none"
                            sx={{ flexGrow: 1, textAlign: "center" }}
                          >
                            Sign in with Google
                          </Typography>
                        </Stack>
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        <Divider sx={{ width: "325%", bgcolor: "lightgray" }} />
                        <Typography sx={{ mx: 2, color: "black" }}>
                          Or
                        </Typography>
                        <Divider sx={{ width: "325%", bgcolor: "lightgray" }} />
                      </Box>
                      <form onSubmit={handleSignIn}>
                        <TextField
                          name="email"
                          label="EMAIL"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={signInValues.email}
                          onChange={onFieldSignInChange}
                          error={fieldSignInHasError("email")}
                          helperText={fieldSignInGetError("email")}
                        />
                        <TextField
                          name="password"
                          label="PASSWORD"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type={showPassword ? "text" : "password"}
                          value={signInValues.password}
                          onChange={onFieldSignInChange}
                          error={fieldSignInHasError("password")}
                          helperText={fieldSignInGetError("password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    showPassword
                                      ? "Hide password" ?? ``
                                      : "Show password"
                                  }
                                >
                                  <IconButton
                                    onClick={handleShowPassword}
                                    onMouseDown={(event) =>
                                      event.preventDefault
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityOff
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <LoadingButton
                          type="submit"
                          disabled={!formSignInState.isValid}
                          fullWidth
                          onClick={handleSignIn}
                          sx={{
                            border: "1px solid #38618c",
                            mt: 3,
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#38618c", // Brighter red on hover
                              "& .MuiTypography-root": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <Typography color="#38618c" fontFamily="Regular">
                            LOGIN
                          </Typography>
                        </LoadingButton>
                      </form>
                      <Typography
                        sx={{ flexGrow: 1, textAlign: "right", mt: 2 }}
                      >
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={handleShowForgotPass}
                        >
                          Forgot Password
                        </Link>
                      </Typography>
                      <Typography
                        sx={{ flexGrow: 1, textAlign: "center", mt: 2 }}
                      >
                        Don't have an account?{" "}
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={handleShowSignUp}
                        >
                          Sign up here.
                        </Link>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
          ) : isSignUp ? (
            <DialogContent sx={{ display: "flex", alignItems: "center" }}>
              {isMobile ? (
                <Box />
              ) : (
                <Box sx={{ width: "50%", pr: 2 }}>
                  {/* Image on the left */}
                  <img
                    src="/assets/images/authbg.jpg"
                    alt="Authentication Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ width: isMobile ? "100%" : "50%" }}
              >
                {/* Login form on the right */}
                <Card elevation={0}>
                  <CardHeader title="SIGN UP TO KUNARCHGPT" titleTypographyProps={{fontSize: isMobile ? "18px" : "24px", textAlign: "center"}} />
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ width: "100%", mt: 2 }}
                    >
                      <Button
                        fullWidth
                        onClick={() =>
                          console.log("Sign up with Microsoft clicked")
                        }
                        color="primary"
                        sx={{
                          backgroundColor: "#f6f6f6",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="flex-start"
                        >
                          <Microsoft fontSize="small" sx={{ color: "black" }} />
                          <Typography
                            color="black"
                            textTransform="none"
                            sx={{ flexGrow: 1, textAlign: "center" }}
                          >
                            Sign up with Microsoft
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        fullWidth
                        onClick={() =>
                          console.log("Sign up with Microsoft clicked")
                        }
                        color="primary"
                        sx={{
                          mt: 2,
                          backgroundColor: "#f6f6f6",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiSvgIcon-root": {
                              color: "white",
                            },
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="flex-start"
                        >
                          <Google fontSize="small" sx={{ color: "black" }} />
                          <Typography
                            color="black"
                            textTransform="none"
                            sx={{ flexGrow: 1, textAlign: "center" }}
                          >
                            Sign up with Google
                          </Typography>
                        </Stack>
                      </Button>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        <Divider sx={{ width: "325%", bgcolor: "lightgray" }} />
                        <Typography sx={{ mx: 2, color: "black" }}>
                          Or
                        </Typography>
                        <Divider sx={{ width: "325%", bgcolor: "lightgray" }} />
                      </Box>
                      <form onSubmit={handleSignup}>
                        <TextField
                          name="username"
                          label="USERNAME"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={signUpValues.username}
                          onChange={onFieldSignUpChange}
                          error={fieldSignUpHasError("username")}
                          helperText={fieldSignUpGetError("username")}
                        />
                        <TextField
                          name="email"
                          label="EMAIL"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={signUpValues.email}
                          onChange={onFieldSignUpChange}
                          error={fieldSignUpHasError("email")}
                          helperText={fieldSignUpGetError("email")}
                          sx={{ mb: 3 }}
                        />
                        <TextField
                          name="phoneNumber"
                          variant="outlined"
                          label="Phone number"
                          color="primary"
                          placeholder="Phone number"
                          value={inputValue}
                          fullWidth
                          error={hasInteracted && !isValid}
                          onChange={handlePhoneValueChange}
                          helperText={
                            hasInteracted &&
                            !isValid &&
                            inputValue.trim() !== "" && (
                              <Typography fontSize="12px" color="#d32f2f">
                                Phone number is invalid
                              </Typography>
                            )
                          }
                          type="tel"
                          inputRef={inputRef}
                          onKeyDown={(e) =>
                            e.key === "+" ||
                            /^[0-9]$/.test(e.key) ||
                            e.key === "Backspace"
                              ? setHasInteracted(true)
                              : e.preventDefault()
                          }
                          sx={{
                            mb: 1,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment
                                position="start"
                                style={{
                                  marginRight: "2px",
                                  marginLeft: "-8px",
                                }}
                              >
                                <Select
                                  MenuProps={{
                                    style: {
                                      height: "300px",
                                      width: "342px",
                                      top: "10px",
                                      left: "-34px",
                                    },
                                    transformOrigin: {
                                      vertical: "top",
                                      horizontal: "left",
                                    },
                                  }}
                                  sx={{
                                    width: "max-content",
                                    // Remove default outline (display only on focus)
                                    fieldset: {
                                      display: "none",
                                    },
                                    '&.Mui-focused:has(div[aria-expanded="false"])':
                                      {
                                        fieldset: {
                                          display: "block",
                                        },
                                      },
                                    // Update default spacing
                                    ".MuiSelect-select": {
                                      padding: "8px",
                                      paddingRight: "24px !important",
                                    },
                                    svg: {
                                      right: 0,
                                    },
                                  }}
                                  value={country}
                                  onChange={(e) => {
                                    setCountry(e.target.value as CountryIso2);
                                    setPhoneCountry(
                                      e.target.value as CountryIso2
                                    ); // Update the phone input country separately
                                    setFormSignUpstate({
                                      ...formSignUpState,
                                      values: {
                                        ...formSignUpState.values,
                                        phoneNumber: "", // Reset phone number when the country changes
                                      },
                                    });
                                  }}
                                  renderValue={(value) => (
                                    <FlagImage
                                      iso2={value}
                                      style={{ display: "flex" }}
                                    />
                                  )}
                                >
                                  {defaultCountries.map((c) => {
                                    const country = parseCountry(c);
                                    return (
                                      <MenuItem
                                        key={country.iso2}
                                        value={country.iso2}
                                      >
                                        <FlagImage
                                          iso2={country.iso2}
                                          style={{ marginRight: "8px" }}
                                        />
                                        <Typography marginRight="8px">
                                          {country.name}
                                        </Typography>
                                        <Typography color="gray">
                                          +{country.dialCode}
                                        </Typography>
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          name="password"
                          label="PASSWORD"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type={showPassword ? "text" : "password"}
                          value={signUpValues.password}
                          onChange={onFieldSignUpChange}
                          error={fieldSignUpHasError("password")}
                          helperText={fieldSignUpGetError("password")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    showPassword
                                      ? "Hide password" ?? ``
                                      : "Show password"
                                  }
                                >
                                  <IconButton
                                    onClick={handleShowPassword}
                                    onMouseDown={(event) =>
                                      event.preventDefault
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityOff
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          name="confirmPassword"
                          label="CONFIRM PASSWORD"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type={showConfirmPassword ? "text" : "password"}
                          value={signUpValues.confirmPassword}
                          onChange={onFieldSignUpChange}
                          error={fieldSignUpHasError("confirmPassword")}
                          helperText={fieldSignUpGetError("confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    showConfirmPassword
                                      ? "Hide password" ?? ``
                                      : "Show password"
                                  }
                                >
                                  <IconButton
                                    onClick={handleShowConfirmClick}
                                    onMouseDown={(event) =>
                                      event.preventDefault
                                    }
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <LoadingButton
                          type="submit"
                          disabled={!formSignUpState.isValid}
                          fullWidth
                          onClick={handleSignup}
                          sx={{
                            border: "1px solid #38618c",
                            mt: 3,
                            color: "white",
                            "&:hover": {
                              backgroundColor: "#38618c", // Brighter red on hover
                              "& .MuiTypography-root": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <Typography color="#38618c" fontFamily="Regular">
                            CREATE AN ACCOUNT
                          </Typography>
                        </LoadingButton>
                      </form>
                      <Typography
                        sx={{ flexGrow: 1, textAlign: "center", mt: 2 }}
                      >
                        Already have an account?{" "}
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={handleShowSignin}
                        >
                          Sign in here.
                        </Link>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
          ) : (
            <DialogContent sx={{ display: "flex", alignItems: "center" }}>
              {isMobile ? (
                <Box />
              ) : (
                <Box sx={{ width: "50%", pr: 2 }}>
                  {/* Image on the left */}
                  <img
                    src="/assets/images/authbg.jpg"
                    alt="Authentication Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Box
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ width: isMobile ? "100%" : "50%" }}
              >
                {/* Login form on the right */}
                <Card elevation={0}>
                  <CardHeader title="FORGOT PASSWORD" titleTypographyProps={{fontSize: isMobile ? "18px" : "24px", textAlign: "center"}}/>
                  <CardContent>
                    <Box
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ width: "100%", mt: 2 }}
                    >
                      <form
                        onSubmit={handleForgotPasswordSubmit}
                        style={{ width: "100%" }}
                      >
                        <TextField
                          name="email"
                          label="EMAIL"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          value={fpValues.email}
                          onChange={onFieldFPChange}
                          error={fieldFPHasError("email")}
                          helperText={fieldFPGetError("email")}
                          sx={{ width: "100%" }}
                        />
                      </form>
                      <LoadingButton
                        type="submit"
                        disabled={!formFPState.isValid}
                        fullWidth
                        onClick={handleForgotPasswordSubmit}
                        sx={{
                          border: "1px solid #38618c",
                          mt: 3,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Typography color="#38618c" fontFamily="Regular">
                          FORGOT PASSWORD
                        </Typography>
                      </LoadingButton>
                      <Typography
                        sx={{ flexGrow: 1, textAlign: "center", mt: 2 }}
                      >
                        Already have an account?{" "}
                        <Link
                          sx={{ cursor: "pointer" }}
                          onClick={handleShowSignin}
                        >
                          Sign in here.
                        </Link>
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
          )}
        </Paper>
      </Dialog>
    </AppBar>
  );
};

export default PublicNavbar;
