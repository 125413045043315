import i18next from '../i18n/i18n';
import { localStorageDelete, localStorageSet } from '../utils';
import { AppStoreState } from './AppStore';

const AppReducer: React.Reducer<AppStoreState, any> = (state, action) => {
  switch (action.type || action.action) {
    case 'CURRENT_USER':
      return {
        ...state,
        currentUser: action.currentUser || action.payload,
      };
    case 'LOG_IN':
      localStorageSet('loginData', action.loginData);
      localStorageSet('tokenExpiry', action.expirationDate);
      localStorageSet('isAuthenticated', true);
      return {
        ...state,
        isAuthenticated: true,
        loginData: action.loginData,
      };
    case 'SIGN_UP':
    case 'LOG_OUT':
      localStorageDelete('loginData');
      localStorageDelete('selectedItem');
      localStorageDelete('tokenExpiry');
      localStorageSet('isAuthenticated', false);
      return {
        ...state,
        isAuthenticated: false,
        currentUser: undefined,
      };
    case 'SERVER_CALL_START':
      return {
        ...state,
        isServerCall: true,
      };
    case 'SERVER_CALL_END':
      return {
        ...state,
        isServerCall: false,
      };
    case 'LANGUAGE_CHANGE':
      const language = action?.language ?? action?.payload;
      localStorageSet('language', language);
      i18next.changeLanguage(language);
      return {
        ...state,
        language: language,
      };
    default:
      return state;
  }
};

export default AppReducer;
