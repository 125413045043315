import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SnackbarProvider } from "notistack";
import axios from "axios";
import { localStorageGet } from "./utils";

axios.interceptors.request.use(
  config => {
    const loginData = Object(localStorageGet('loginData'));
    if (loginData != null && loginData.authToken != null) {
      config.headers['Authorization'] = `Bearer ${loginData.authToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <SnackbarProvider>
    <App />
  </SnackbarProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
