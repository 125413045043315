import { LoadingButton } from "@mui/lab";
import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { AuthenticationApi } from "../../service/swagger/apis/authentication-api";
import axios from "axios";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";

const EmailVerification = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useAppStore();
  const token = new URLSearchParams(location.search).get("token");
  const email = new URLSearchParams(location.search).get("email");

  useEffect(() => {
    async function verifyEmail() {
      try {
        const delayedResponse = await new Promise((resolve) => {
          setTimeout(async () => {
            const response =
              await new AuthenticationApi().apiAuthVerifyEmailGet(
                token!,
                email!
              );
            resolve(response);
          }, 3000); // 3000 milliseconds = 3 seconds
        });

        if (axios.isAxiosError(delayedResponse)) {
          enqueueSnackbar(delayedResponse.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          enqueueSnackbar(
            "Your email has been verified. Please login to continue.",
            {
              variant: "success",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
          navigate("/", { replace: true });
        }
      } catch (e: any) {
        console.log(e);
      }
    }

    if (token && email) {
      verifyEmail();
    } else {
      enqueueSnackbar("Token or email is missing", {
        variant: "error",
        anchorOrigin: { vertical: "bottom", horizontal: "center" },
      });
    }
  }, [token, email]);

  return (
    <Container
      maxWidth={false}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center contents horizontally
        justifyContent: "center", // Center contents vertically
        p: 2,
        height: "100vh", // Set height to 100% of the viewport
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          mb: 5,
        }}
      >
        <img
          alt="KunarchGPT"
          src="/assets/images/ArcGPT-Logo2.png"
          style={{ width: "50px", height: "50px" }}
        />
        <Typography
          fontFamily="Medium"
          color="black"
          fontWeight="500"
          fontSize="30px"
          ml={1}
        >
          KunarchGPT
        </Typography>
      </Box>
      <Box sx={{ borderRadius: "20px", mb: 5 }}>
        <img
          alt="KunarchGPT"
          src="/assets/images/email.gif"
          style={{ width: "250px", height: "200px", borderRadius: "20px" }}
        />
      </Box>

      <Box display="flex">
        <Typography
          fontFamily="Semibold"
          fontSize="36px"
          fontWeight="600"
          whiteSpace="pre-line"
          textAlign="center"
          sx={{
            mb: 5,
          }}
        >
          {"Successfully verified. \nRedirecting..."}
        </Typography>
      </Box>
      <Box>
        <MoonLoader color="#ff5f1f" />
      </Box>
      <Box display="flex" flexDirection="column" sx={{ mt: 5 }}>
        <Typography
          fontFamily="Medium"
          fontWeight="500"
          textAlign="center"
          sx={{ mb: 1 }}
        >
          {"We just sent an email to your address."}
        </Typography>
        <Typography
          fontFamily="Medium"
          fontWeight="500"
          textAlign="center"
          sx={{ mb: 1 }}
        >
          {
            "Please check your email and select the link provided to verify your address."
          }
        </Typography>
        <Typography
          fontFamily="Medium"
          fontWeight="500"
          whiteSpace="pre-line"
          textAlign="center"
        >
          {
            "You will receive your email within 1-2 minutes.\nIf you did not receive it, please click the resend button"
          }
        </Typography>
      </Box>
      <LoadingButton
        sx={{
          backgroundColor: "#4f6b82",
          mt: 5,
          px: 3,
          color: "white",
          borderRadius: "50px",
          "&:hover": {
            backgroundColor: "#5190c4", // Brighter red on hover
          },
        }}
      >
        <Typography
          fontFamily="Medium"
          fontWeight="500"
          fontSize="20px"
          textTransform="none"
        >
          {"Resend Verification Email"}
        </Typography>
      </LoadingButton>
    </Container>
  );
};

export default EmailVerification;
