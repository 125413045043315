import React, { SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Typography,
  Button,
  Divider,
  Popover,
  TextField,
  CardHeader,
  Box,
  Card,
  Dialog,
  DialogContent,
  Paper,
  CardContent,
  InputAdornment,
  Tooltip,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Add,
  ExitToApp,
  Password,
  Upgrade,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useEventLogout } from "../../hooks/auth";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import { GLOBAL_ROLES } from "../../utils";
import { AppDropdown } from "../../components/AppDropdown";
import { useAppForm } from "../../utils/form";
import { LoadingButton } from "@mui/lab";
import { AuthenticationApi } from "../../service/swagger/apis/authentication-api";
import axios from "axios";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const VALIDATION_FORM_CP = {
  oldPassword: {
    presence: true,
  },
  newPassword: {
    presence: true,
    length: {
      minimum: 8,
      message: "must be minimum 8 characters",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      message: "must include numbers and special characters",
    },
  },
  confirmPassword: {
    presence: true,
    equality: {
      attribute: "newPassword",
      message: "and New Password do not match.",
    },
  },
};

interface FormStateCPValues {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const PrivateNavbar = () => {
  const [state, dispatch] = useAppStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loggedInUser] = useState(state.loginData.payload.userData ?? "");
  const navigate = useNavigate();
  const [
    formCPState,
    setFormCPstate,
    onFieldCPChange,
    fieldCPGetError,
    fieldCPHasError,
  ] = useAppForm({
    validationSchema: VALIDATION_FORM_CP,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    } as FormStateCPValues,
  });

  const cpValues = formCPState.values as FormStateCPValues;
  const [fullName] = useState(
    state.loginData.payload.userData.userName ?? "User"
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const onLogout = useEventLogout();
  const [isChangePass, setIsChangePass] = useState<boolean>(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleProfileClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileClose = () => {
    setAnchorEl(null);
  };

  const handleChangePassword = () => {
    setAnchorEl(null);
    setIsChangePass(true);
  };

  const handleChangePassClose = () => {
    setIsChangePass(false);
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setFormCPstate({
      values: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      isValid: false,
      touched: {},
      errors: {},
    });
  };

  const handleShowOldClick = useCallback(() => {
    setShowOldPassword((oldValue) => !oldValue);
  }, []);

  const handleShowNewClick = useCallback(() => {
    setShowNewPassword((oldValue) => !oldValue);
  }, []);

  const handleShowConfirmClick = useCallback(() => {
    setShowConfirmPassword((oldValue) => !oldValue);
  }, []);

  // add action to an individual snackbar
  const action = (
    <>
      <button
        style={{
          color: "white",
          fontFamily: "Medium",
          fontWeight: "500",
          background: "none",
          border: "none",
          cursor: "pointer",
        }}
        onClick={() => {
          closeSnackbar();
        }}
      >
        Dismiss
      </button>
    </>
  );

  const handleFormSubmit = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();

      try {
        setLoading(true);
        const response =
          await new AuthenticationApi().apiAuthChangePasswordPost({
            userId: loggedInUser.id,
            currentPassword: cpValues.oldPassword,
            newPassword: cpValues.newPassword,
            confirmNewPassword: cpValues.confirmPassword,
          });

        if (axios.isAxiosError(response)) {
          enqueueSnackbar(response.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          handleChangePassClose();
          enqueueSnackbar("Your password has been successfully changed.", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [cpValues, enqueueSnackbar]
  );

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        minHeight: 45,
        backgroundColor: "#fafafa",
      }}
    >
      <Toolbar
        sx={{
          minHeight: 45,
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <IconButton
          onClick={() => {
            navigate("/", { replace: true });
          }}
          disableFocusRipple
          disableRipple
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <img
              alt="ArcGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: isMobile ? "75px" : "200px",
                height: isMobile ? "75px" : "200px",
              }}
            />
          </Box>
        </IconButton>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          marginLeft="auto"
          alignItems="center"
        >
          {!isMobile ? (
            <Button
              onClick={() => navigate("/chat")}
              disableElevation
              variant="contained"
              sx={{
                fontFamily: "Light",
                backgroundColor: "#fafafa",
                padding: "5px 5px",
                mr: "20px",
                ml: "20px",
                display: "flex",
                alignItems: "center",
                "&:hover": {
                  backgroundColor: "#3f301d",
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiTypography-root": {
                    color: "white",
                  },
                },
              }}
            >
              <Add
                sx={{
                  mr: "5px",
                  color: "grey",
                }}
              />
              <Typography
                fontFamily="Light"
                color="grey"
                sx={{
                  textTransform: "none",
                  mr: "5px",
                }}
              >
                NEW CHAT
              </Typography>
            </Button>
          ) : (
            <Button
              onClick={() => navigate("/chat")}
              disableElevation
              variant="contained"
              sx={{
                fontFamily: "Light",
                padding: "5px 5px",
                mr: "20px",
                ml: "20px",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#3f301d",
                "& .MuiSvgIcon-root": {
                  color: "white",
                },
                "& .MuiTypography-root": {
                  color: "white",
                },
              }}
            >
              <Add
                sx={{
                  mr: "5px",
                  color: "grey",
                }}
              />
              <Typography
                fontFamily="Light"
                color="grey"
                sx={{
                  textTransform: "none",
                  mr: "5px",
                }}
              >
                CHAT
              </Typography>
            </Button>
          )}
          <IconButton
            onClick={handleProfileClick}
            disableFocusRipple
            disableRipple
          >
            <Avatar sx={{ bgcolor: "#cacaca" }}>
              <Typography textAlign="center">
                {fullName
                  .split(" ")
                  .slice(0, 2)
                  .map((word: string) => word[0].toUpperCase())
                  .join("")}
              </Typography>
            </Avatar>
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleProfileClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Box p={2}>
              <Button
                // onClick={() => {
                //   navigate("/settings/profile/:userId");
                //   setAnchorEl(null);
                // }}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    fontFamily="Regular"
                    variant="subtitle1"
                    fontWeight="bold"
                    textTransform="none"
                    textAlign="left"
                  >
                    {loggedInUser.userName}
                  </Typography>
                  <Typography
                    fontFamily="Regular"
                    color="grey"
                    variant="body2"
                    mb={1}
                    textTransform="none"
                  >
                    {loggedInUser.email}
                  </Typography>
                </Box>
              </Button>
              <Divider sx={{ mb: 1 }} />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Button
                  onClick={handleChangePassword}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Password
                    fontSize="small"
                    sx={{ marginRight: 1, color: "#696969" }}
                  />
                  <Typography
                    color="#696969"
                    variant="body2"
                    textTransform="none"
                    fontFamily="Regular"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Change Password
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    navigate("/plans");
                    setAnchorEl(null);
                  }}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <Upgrade
                    fontSize="small"
                    sx={{ marginRight: 1, color: "#696969" }}
                  />
                  <Typography
                    color="#696969"
                    variant="body2"
                    textTransform="none"
                    fontFamily="Regular"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Upgrade Plan
                  </Typography>
                </Button>
                <Button
                  onClick={() => {
                    onLogout();
                    enqueueSnackbar("You have logged out.", {
                      action,
                      variant: "success",
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "center",
                      },
                    });
                  }}
                  sx={{ display: "flex", justifyContent: "flex-start" }}
                >
                  <ExitToApp
                    fontSize="small"
                    sx={{ marginRight: 1, color: "red" }}
                  />
                  <Typography
                    color="red"
                    variant="body2"
                    textTransform="none"
                    fontFamily="Regular"
                    sx={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    Logout
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Toolbar>
      <Dialog
        open={isChangePass}
        onClose={handleChangePassClose}
        fullWidth
        maxWidth={isMobile ? "sm" : "lg"}
      >
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: 9999,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Circular progress */}
            <CircularProgress sx={{ color: "#ffffff" }} />
          </div>
        )}
        <Paper sx={{ backgroundColor: "transparent", overflow: "auto" }}>
          <DialogContent sx={{ display: "flex", alignItems: "center" }}>
          {isMobile ? (
                <Box />
              ) : (
                <Box sx={{ width: "50%", pr: 2 }}>
                  {/* Image on the left */}
                  <img
                    src="/assets/images/authbg.jpg"
                    alt="Authentication Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              sx={{ width: isMobile ? "100%" : "50%" }}
            >
              {/* Login form on the right */}
              <Card elevation={0}>
                <CardHeader title="CHANGE PASSWORD" titleTypographyProps={{fontSize: isMobile ? "18px" : "24px", textAlign: "center"}} />
                <CardContent>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection="column"
                    sx={{ width: "100%", mt: 2 }}
                  >
                    <form onSubmit={handleFormSubmit}>
                      <TextField
                        name="oldPassword"
                        label="OLD PASSWORD"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type={showOldPassword ? "text" : "password"}
                        value={cpValues.oldPassword}
                        onChange={onFieldCPChange}
                        error={fieldCPHasError("oldPassword")}
                        helperText={fieldCPGetError("oldPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={
                                  showConfirmPassword
                                    ? "Hide password" ?? ``
                                    : "Show password"
                                }
                              >
                                <IconButton
                                  onClick={handleShowOldClick}
                                  onMouseDown={(event) => event.preventDefault}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        name="newPassword"
                        label="NEW PASSWORD"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type={showNewPassword ? "text" : "password"}
                        value={cpValues.newPassword}
                        onChange={onFieldCPChange}
                        error={fieldCPHasError("newPassword")}
                        helperText={fieldCPGetError("newPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={
                                  showConfirmPassword
                                    ? "Hide password" ?? ``
                                    : "Show password"
                                }
                              >
                                <IconButton
                                  onClick={handleShowNewClick}
                                  onMouseDown={(event) => event.preventDefault}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <TextField
                        name="confirmPassword"
                        label="CONFIRM PASSWORD"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type={showConfirmPassword ? "text" : "password"}
                        value={cpValues.confirmPassword}
                        onChange={onFieldCPChange}
                        error={fieldCPHasError("confirmPassword")}
                        helperText={fieldCPGetError("confirmPassword")}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip
                                title={
                                  showConfirmPassword
                                    ? "Hide password" ?? ``
                                    : "Show password"
                                }
                              >
                                <IconButton
                                  onClick={handleShowConfirmClick}
                                  onMouseDown={(event) => event.preventDefault}
                                >
                                  {showConfirmPassword ? (
                                    <VisibilityOff
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  ) : (
                                    <Visibility
                                      sx={{
                                        color: "black",
                                      }}
                                    />
                                  )}
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <LoadingButton
                        type="submit"
                        disabled={!formCPState.isValid}
                        fullWidth
                        onClick={handleFormSubmit}
                        sx={{
                          border: "1px solid #38618c",
                          mt: 3,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Typography color="#38618c" fontFamily="Regular">
                          CHANGE PASSWORD
                        </Typography>
                      </LoadingButton>
                    </form>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </DialogContent>
        </Paper>
      </Dialog>
    </AppBar>
  );
};

export default PrivateNavbar;
