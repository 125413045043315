import { useCallback } from "react";
import { useAppStore } from "../store";
import { useNavigate } from "react-router-dom";

export function useIsAuthenticated() {
    const [state,] = useAppStore();
    let result = state.isAuthenticated;

    return result;
};

export function useEventLogout() {
    const [, dispatch] = useAppStore();
    const navigate = useNavigate();

    return useCallback(() => {
        dispatch({type: 'LOG_OUT'});
        navigate('/', {replace: true});
    }, [dispatch]);
}