import React from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import {
  Stack,
  Box,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FunctionComponent, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import PublicNavbar from "./Navbar/PublicNavbar";
import { Instagram, LinkedIn, Mail } from "@mui/icons-material";
import ResetPassword from "../views/auth/ResetPassword";
import LandingPage from "../views/home/LandingPage";
import ParticlesComponent from "../components/ParticlesComponent"; // Import the ParticlesComponent

const TITLE_PRIVATE = "KunarchGPT";

const PublicLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["text"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const title = t(TITLE_PRIVATE);
  document.title = title;

  // Define your routes
  const routes = [
    { path: "/", element: <LandingPage /> },
    { path: "/arcgpt", element: <LandingPage /> },
    // Add more routes as needed
  ];

  // Check if the current route matches any of the specified routes
  const matchedRoute = useRoutes(routes);

  return (
    <Stack direction="column" style={{ minHeight: 0, flex: 1 }}>
      <Box
        flexGrow={1}
        position="relative"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -10,
        }}
      >
        <ParticlesComponent />
        {matchedRoute && <PublicNavbar />}
        <Box sx={{ position: "relative" }}>{children}</Box>
        {matchedRoute && (
          <Box
            sx={{
              backgroundColor: "black",
              color: "white",
              textAlign: "center",
              p: 2,
              position: "relative",
            }}
          >
            <img
              alt="ArcGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: isMobile ? "50px" : "100px",
                height: isMobile ? "50px" : "100px",
              }}
            />
            <Typography fontFamily="Regular" variant="body2" mb={1}>
              {"© KunarchGPT UK"}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              mb={1}
            >
              <Mail
                onClick={() =>
                  window.open("mailto:sales@kunarchgpt.uk", "_blank")
                }
                fontSize="small"
                sx={{ mr: 1 }}
              />
              {/* <LinkedIn
                onClick={() =>
                  window.open(
                    "http://linkedin.com/in/faran-lodhi-64a645a2",
                    "_blank"
                  )
                }
                fontSize="small"
                sx={{ mr: 1 }}
              />
              <Instagram
                onClick={() =>
                  window.open("https://www.instagram.com/flaaa9667", "_blank")
                }
                fontSize="small"
              /> */}
            </Box>
            <Link href="/terms-conditions" underline="none" color="white">
              <Typography fontFamily="Regular" variant="body2">
                {"Terms & Conditions"}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default PublicLayout;
