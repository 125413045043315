import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import textEN from '../locales/en/text.json';
import textKZ from '../locales/kz/text.json';
import textRU from '../locales/ru/text.json';

const resources = {
  en: {
    text: textEN,
  },
  ru: {
    text: textRU, // Assign the correct JSON file for Russian (ru)
  },
  kz: {
    text: textKZ, // Assign the correct JSON file for Kazakh (kz)
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: 'en',
  debug: false,
  fallbackLng: 'en',
  saveMissing: true,
});

export default i18next;