import { FunctionComponent, PropsWithChildren, useEffect } from 'react';
import axios from 'axios';
import { useAppStore } from '../store';

const WithAxios: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [, dispatch] = useAppStore();

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => {
        dispatch({ type: 'SERVER_CALL_START' });
        return config;
      },
      (error) => {
        dispatch({ type: 'SERVER_CALL_END' });
        return error;
      }
    );

    axios.interceptors.response.use(
      (response) => {
        dispatch({ type: 'SERVER_CALL_END' });
        return response;
      },
      (error) => {
        dispatch({ type: 'SERVER_CALL_END' });
        return error;
      }
    );
  }, [dispatch]);

  return <>{children}</>;
};

export default WithAxios;
