import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import { useIsAuthenticated } from "../hooks/auth";

const Routes = () => {
  const isAuthenticated = useIsAuthenticated();

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />;
};

export default Routes;
