import { IS_SERVER } from './utils';

export function sessionStorageGet(name: string, defaultValue: any = ''): any {
  if (IS_SERVER) {
    return defaultValue;
  }

  const valueFromStore = sessionStorage.getItem(name);
  if (valueFromStore === null) {
    return defaultValue;
  }

  try {
    const jsonParsed: unknown = JSON.parse(valueFromStore);
    if (['boolean', 'number', 'bigint', 'string', 'object'].includes(typeof jsonParsed)) {
      return jsonParsed;
    }
  } catch (error) {}

  return valueFromStore;
}

export function sessionStorageSet(name: string, value: any) {
  if (IS_SERVER) {
    return;
  }
  if (typeof value === 'undefined') {
    return;
  }
  let valueAsString: string;
  if (typeof value === 'object') {
    valueAsString = JSON.stringify(value);
  } else {
    valueAsString = String(value);
  }

  sessionStorage.setItem(name, valueAsString);
}

export function sessionStorageDelete(name: string) {
  if (IS_SERVER) {
    return;
  }
  if (name) {
    sessionStorage.removeItem(name);
  } else {
    sessionStorage.clear();
  }
}
