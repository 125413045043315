/* tslint:disable */
/* eslint-disable */
/**
 * Sms API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import globalAxios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
import { ChangePasswordDto } from '../models';
import { JwtTokenDto } from '../models';
import { LoginDto } from '../models';
import { RegisterDto } from '../models';
import { ResetPasswordDto } from '../models';
import { UserDto } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthChangePasswordPost: async (body?: ChangePasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/change-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthCompleteForgotPasswordPost: async (body?: ResetPasswordDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/complete-forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthForgotPasswordRequestPost: async (email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/forgot-password-request`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthGoogleLoginCallbackGet: async (returnUrl?: string, remoteError?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/google-login-callback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }

            if (remoteError !== undefined) {
                localVarQueryParameter['remoteError'] = remoteError;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthGoogleLoginGet: async (provider?: string, returnUrl?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/google-login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            if (returnUrl !== undefined) {
                localVarQueryParameter['returnUrl'] = returnUrl;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthLoginPost: async (body?: LoginDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegisterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthRegisterPost: async (body?: RegisterDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            let needsSerialization = (typeof body !== "string");
            if (localVarRequestOptions.headers != null) {
                needsSerialization = needsSerialization || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            }
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(body !== undefined ? body : {}) : (body || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthVerifyEmailGet: async (token?: string, email?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions :AxiosRequestConfig = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("Authorization")
                    : await configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            const query = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                query.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.params) {
                query.set(key, options.params[key]);
            }
            localVarUrlObj.search = (new URLSearchParams(query)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthChangePasswordPost(body?: ChangePasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<boolean>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthChangePasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthCompleteForgotPasswordPost(body?: ResetPasswordDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<boolean>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthCompleteForgotPasswordPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthForgotPasswordRequestPost(email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<boolean>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthForgotPasswordRequestPost(email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthGoogleLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthGoogleLoginCallbackGet(returnUrl, remoteError, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthGoogleLoginGet(provider?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<void>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthGoogleLoginGet(provider, returnUrl, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {LoginDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthLoginPost(body?: LoginDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<JwtTokenDto>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthLoginPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {RegisterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRegisterPost(body?: RegisterDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<UserDto>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthRegisterPost(body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthVerifyEmailGet(token?: string, email?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => Promise<AxiosResponse<boolean>>> {
            const localVarAxiosArgs = await AuthenticationApiAxiosParamCreator(configuration).apiAuthVerifyEmailGet(token, email, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs :AxiosRequestConfig = {...localVarAxiosArgs.options, url: basePath + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @param {ChangePasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthChangePasswordPost(body?: ChangePasswordDto, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return AuthenticationApiFp(configuration).apiAuthChangePasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ResetPasswordDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthCompleteForgotPasswordPost(body?: ResetPasswordDto, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return AuthenticationApiFp(configuration).apiAuthCompleteForgotPasswordPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthForgotPasswordRequestPost(email?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return AuthenticationApiFp(configuration).apiAuthForgotPasswordRequestPost(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnUrl] 
         * @param {string} [remoteError] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthGoogleLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).apiAuthGoogleLoginCallbackGet(returnUrl, remoteError, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {string} [returnUrl] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthGoogleLoginGet(provider?: string, returnUrl?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<void>> {
            return AuthenticationApiFp(configuration).apiAuthGoogleLoginGet(provider, returnUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LoginDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthLoginPost(body?: LoginDto, options?: AxiosRequestConfig): Promise<AxiosResponse<JwtTokenDto>> {
            return AuthenticationApiFp(configuration).apiAuthLoginPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegisterDto} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthRegisterPost(body?: RegisterDto, options?: AxiosRequestConfig): Promise<AxiosResponse<UserDto>> {
            return AuthenticationApiFp(configuration).apiAuthRegisterPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [token] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthVerifyEmailGet(token?: string, email?: string, options?: AxiosRequestConfig): Promise<AxiosResponse<boolean>> {
            return AuthenticationApiFp(configuration).apiAuthVerifyEmailGet(token, email, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {ChangePasswordDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthChangePasswordPost(body?: ChangePasswordDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return AuthenticationApiFp(this.configuration).apiAuthChangePasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {ResetPasswordDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthCompleteForgotPasswordPost(body?: ResetPasswordDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return AuthenticationApiFp(this.configuration).apiAuthCompleteForgotPasswordPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthForgotPasswordRequestPost(email?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return AuthenticationApiFp(this.configuration).apiAuthForgotPasswordRequestPost(email, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [returnUrl] 
     * @param {string} [remoteError] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthGoogleLoginCallbackGet(returnUrl?: string, remoteError?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).apiAuthGoogleLoginCallbackGet(returnUrl, remoteError, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [provider] 
     * @param {string} [returnUrl] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthGoogleLoginGet(provider?: string, returnUrl?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<void>> {
        return AuthenticationApiFp(this.configuration).apiAuthGoogleLoginGet(provider, returnUrl, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {LoginDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthLoginPost(body?: LoginDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<JwtTokenDto>> {
        return AuthenticationApiFp(this.configuration).apiAuthLoginPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {RegisterDto} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthRegisterPost(body?: RegisterDto, options?: AxiosRequestConfig) : Promise<AxiosResponse<UserDto>> {
        return AuthenticationApiFp(this.configuration).apiAuthRegisterPost(body, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * 
     * @param {string} [token] 
     * @param {string} [email] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public async apiAuthVerifyEmailGet(token?: string, email?: string, options?: AxiosRequestConfig) : Promise<AxiosResponse<boolean>> {
        return AuthenticationApiFp(this.configuration).apiAuthVerifyEmailGet(token, email, options).then((request) => request(this.axios, this.basePath));
    }
}
