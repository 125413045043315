import { ArrowBackIos, CheckCircle } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SubscriptionPlanDto } from "../../service/swagger";
import { useEffect, useState } from "react";
import { SubscriptionPlanApi } from "../../service/swagger/apis/subscription-plan-api";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutApi } from "../../service/swagger/apis/checkout-api";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const Plans = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [plans, setPlans] = useState<SubscriptionPlanDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [seshId, setSessionId] = useState<string>("");
  const [pubKey, setPubKey] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchPlans() {
      try {
        setLoading(true);
        const response = (
          await new SubscriptionPlanApi().apiSubscriptionPlansGet()
        ).data;

        // Sort the plans by price in ascending order
        const sortedPlans = response.sort((a, b) => a.price! - b.price!);

        setPlans(sortedPlans);
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    fetchPlans();
  }, []);

  const handleSubscription = async (id: string) => {
    try {
      const response = await new CheckoutApi().apiCheckoutPost(id);
      if (axios.isAxiosError(response)) {
        enqueueSnackbar(response.response?.data.errors[0], {
          variant: "error",
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
        });
      } else {
        setSessionId(response.data.sessionId!);
        setPubKey(response.data.publishableKey!);
        console.log("pubKey", pubKey);
        console.log("session", seshId);

        // Redirect to Stripe Checkout
        const stripe = await loadStripe(response.data.publishableKey!);
        const { error } = await stripe!.redirectToCheckout({
          sessionId: response.data.sessionId!,
        });

        if (error) {
          enqueueSnackbar(error.message, {
            variant: "error",
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
          });
        }
      }
    } catch (e: any) {
      console.log(e);
    }
  };

  useEffect(() => {
    console.log("pubKey", pubKey);
    console.log("session", seshId);
  }, [pubKey, seshId]);

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        elevation={0}
        style={{ backgroundColor: "transparent", height: "60px" }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate("/")}
          >
            <ArrowBackIos sx={{ color: "black" }} />
          </IconButton>
          <Typography
            fontFamily="Semibold"
            variant="h5"
            color="black"
            sx={{ ml: 2 }}
          >
            Plans
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center contents horizontally
          justifyContent: "center", // Center contents vertically
          minHeight: "100vh", // Set height to 100% of the viewport
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Circular progress */}
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          ) : (
            <Box
              display="flex"
              flexDirection={isMobile ? "column" : "row"}
              justifyContent="center"
              alignItems="center"
              mt={isMobile ? 5 : 0}
            >
              {plans.map((plan) => (
                <Box
                  key={plan.id}
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  alignItems="flex-start" // Align items to the left
                  sx={{
                    width: "350px",
                    height: "410px",
                    border: "1px solid #e5e5e5",
                    borderRadius: "50px",
                    backgroundColor: "#f3f3f3",
                    p: 8,
                    m: 5,
                  }}
                >
                  <Typography
                    fontFamily="Semibold"
                    fontWeight="600"
                    fontSize="24px"
                    color="black"
                    sx={{
                      p: "3px",
                      mb: 3,
                    }}
                  >
                    {plan?.title}
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Typography
                      fontFamily="Semibold"
                      fontWeight="600"
                      fontSize="40px"
                      color="black"
                      sx={{
                        mr: 1, // Add margin to separate price from the following text
                      }}
                    >
                      GBP£{plan?.price!}
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Typography
                        fontFamily="Semibold"
                        fontWeight="600"
                        fontSize="12px"
                        color="#c2c2c2"
                      >
                        per
                      </Typography>
                      <Typography
                        fontFamily="Semibold"
                        fontWeight="600"
                        fontSize="12px"
                        color="#c2c2c2"
                      >
                        month
                      </Typography>
                    </Box>
                  </Box>
                  <Typography
                    fontFamily="Semibold"
                    fontWeight="600"
                    fontSize="12px"
                    color="#c2c2c2"
                  >
                    Billed monthly
                  </Typography>
                  <Button
                    onClick={() => handleSubscription(plan.id!)}
                    fullWidth
                    sx={{
                      backgroundColor: "#4f6b82",
                      mt: 3,
                      color: "white",
                      borderRadius: "50px",
                      "&:hover": {
                        backgroundColor: "#4f6b82", // Brighter red on hover
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Medium"
                      fontWeight="500"
                      fontSize="20px"
                      textTransform="none"
                    >
                      Subscribe
                    </Typography>
                  </Button>
                  <Typography
                    mt={1}
                    fontFamily="Semibold"
                    fontWeight="600"
                    fontSize="12px"
                    color="black"
                  >
                    This includes:
                  </Typography>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <CheckCircle sx={{ fontSize: "16px", marginRight: 1 }} />
                    <Typography
                      fontFamily="Semibold"
                      fontWeight="600"
                      fontSize="12px"
                      color="black"
                      textAlign="center"
                    >
                      {plan?.noOfSearches} searches
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Plans;
