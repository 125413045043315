import React from "react";
import { Container, Link, Typography } from "@mui/material";

const Terms = () => {
  const handleLinkClick = () => {
    window.open("https://www.kunarchgpt.uk", "_blank");
  };
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Typography
        fontFamily="Bold"
        fontWeight="bold"
        variant="h4"
        textAlign="center"
        gutterBottom
      >
        Privacy Policy
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        FLAAA Ltd. ("we", "our", or "us") operates https://www.kunarchgpt.uk
        (the "Service"). This page informs you of our policies regarding the
        collection, use, and disclosure of personal data when you use our
        Service and the choices you have associated with that data.
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        1. Information We Collect
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        We may collect and process the following data about you:
        <ul>
          <li>
            Personal Data: This may include your name, email address, postal
            address, phone number, payment information, and any other
            information you provide to us when subscribing to our service.
          </li>
          <li>
            Usage Data: We may also collect information about how you use our
            Service, such as your IP address, browser type, referring/exit
            pages, and operating system.
          </li>
        </ul>
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        2. Use of Personal Data
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        We will only use your Personal Data for the following purposes:
        <ul>
          <li>To provide and maintain our Service.</li>
          <li>To process your subscription and payment transactions.</li>
          <li>
            To communicate with you about your subscription and any updates or
            changes to our Service.
          </li>
          <li>To comply with legal obligations.</li>
        </ul>
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        3. Legal Basis for Processing Personal Data
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        Our legal basis for collecting and processing your Personal Data is:
        <ul>
          <li>
            The necessity of processing for the performance of a contract to
            which you are a party (i.e., your subscription to our service).
          </li>
          <li>Compliance with legal obligations to which we are subject.</li>
          <li>
            Our legitimate interests, such as improving our Service and
            marketing our products to you, provided such interests are not
            overridden by your rights and interests.
          </li>
        </ul>
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        4. Data Retention
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        We will retain your Personal Data only for as long as is necessary for
        the purposes set out in this Privacy Policy. We will retain and use your
        Personal Data to the extent necessary to comply with our legal
        obligations (for example, if we are required to retain your data to
        comply with applicable laws), resolve disputes, and enforce our legal
        agreements and policies.
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        5. Data Sharing and Disclosure
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        We may share your Personal Data with third-party service providers who
        assist us in operating our Service, conducting our business, or
        servicing you. We may also share your Personal Data in the following
        circumstances:
        <ul>
          <li>With your consent.</li>
          <li>To comply with legal obligations.</li>
          <li>To protect and defend our rights or property.</li>
          <li>
            In connection with a corporate transaction, such as a merger, sale
            of assets, or acquisition.
          </li>
        </ul>
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        6. International Data Transfers
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country, or other governmental jurisdiction where the data protection
        laws may differ from those of your jurisdiction.
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        7. Your Rights
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        You have certain rights regarding your Personal Data, including the
        right to access, correct, or delete the Personal Data we hold about you.
        You also have the right to object to or restrict the processing of your
        Personal Data and the right to data portability. To exercise these
        rights, please contact us using the contact information provided below.
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        8. Changes to This Privacy Policy
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        9. Contact Us
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        If you have any questions about this Privacy Policy, please contact us
        at enquiry@kunarchgpt.uk
      </Typography>

      <Typography
        fontFamily="Semibold"
        variant="h6"
        fontWeight="600"
        gutterBottom
      >
        10. Complaints
      </Typography>
      <Typography fontFamily="Regular" variant="body1" paragraph>
        If you believe that we have not complied with this Privacy Policy or
        applicable data protection laws, you have the right to lodge a complaint
        with the Information Commissioner's Office (ICO) or other relevant
        supervisory authority.
      </Typography>

      <Typography fontFamily="Regular" variant="body1" paragraph>
        By using our Service, you consent to the collection and use of your
        Personal Data as described in this Privacy Policy.
      </Typography>

      <Typography fontFamily="Regular" variant="body1" paragraph>
        Last updated: 30th March 2024
      </Typography>
      <Typography fontFamily="Regular" variant="body1">
        FLAAA Ltd.{" "}
        <Link
          underline="none"
          sx={{ fontFamily: "Semibold" }}
          onClick={handleLinkClick}
          style={{ cursor: "pointer" }}
        >
          www.kunarchgpt.uk
        </Link>
      </Typography>
    </Container>
  );
};

export default Terms;
