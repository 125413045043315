import { Route, Routes } from "react-router-dom";
import NotFoundViewView from "../views/NotFoundView";
import LandingPage from "../views/home/LandingPage";
import ResetPassword from "../views/auth/ResetPassword";
import NotFoundView from "../views/NotFoundView";
import EmailVerification from "../views/auth/EmailVerification";
import Terms from "../views/info/Terms";
import Redirection from "../views/auth/Redirection";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route key="home" path="/" element={<LandingPage />} />
      <Route key="arcgpt" path="/arcgpt" element={<LandingPage />} />
      <Route
        key="reset-password"
        path="/reset-password"
        element={<ResetPassword />}
      />
      <Route
        key="emailVerification"
        path="/email-verification"
        element={<EmailVerification />}
      />
      <Route key="Redirection" path="/redirection" element={<Redirection/>} />,
      <Route key="terms" path="/terms-conditions" element={<Terms />} />
      <Route path="*" element={<NotFoundView />} />
    </Routes>
  );
};

export default PublicRoutes;
