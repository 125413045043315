import {
  useContext,
  useReducer,
  createContext,
  FunctionComponent,
  Dispatch,
  ComponentType,
  PropsWithChildren,
} from 'react';
import { localStorageGet } from '../utils';
import AppReducer from './AppReducer';

export interface AppStoreState {
  isAuthenticated: boolean;
  tokenExpiry: string | undefined;
  currentUser?: object | undefined;
  loginData: any | undefined;
  language: string;
  isServerCall: boolean;
  selectedItem: string;
}

const INITIAL_APP_STATE: AppStoreState = {
  isAuthenticated: false,
  tokenExpiry: undefined,
  language: `en`,
  loginData: undefined,
  isServerCall: false,
  selectedItem: '/user-management',
};

type AppContextReturningType = [AppStoreState, Dispatch<any>];
const AppContext = createContext<AppContextReturningType>([INITIAL_APP_STATE, () => null]);

const AppStoreProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const isAuthenticated = Boolean(localStorageGet('isAuthenticated'));
  const loginData = Object(localStorageGet('loginData'));
  const tokenExpiry = localStorageGet('tokenExpiry');
  let language = localStorageGet('language');
  if (language !== null && language === undefined && language !== '') {
    language = String(language);
  } else {
    language = INITIAL_APP_STATE.language;
  }

  const initialState: AppStoreState = {
    ...INITIAL_APP_STATE,
    isAuthenticated: isAuthenticated,
    tokenExpiry: tokenExpiry,
    loginData: loginData,
    language: language,
  };

  const value: AppContextReturningType = useReducer(AppReducer, initialState);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

const useAppStore = (): AppContextReturningType => useContext(AppContext);

interface withAppStoreProps {
  store: object;
}

const withAppStore =
  (Component: ComponentType<withAppStoreProps>): FunctionComponent =>
  (props) => {
    return <Component {...props} store={useAppStore()} />;
  };

export { AppStoreProvider as AppStore, AppContext, useAppStore, withAppStore };
