import { Box, Container, Typography, Divider } from "@mui/material";
import { jwtDecode } from "jwt-decode";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import { enqueueSnackbar } from "notistack";


const Redirection = () => {

const location = useLocation();
const token = new URLSearchParams(location.search).get("jwttoken");
const [state, dispatch] = useAppStore();
const navigate = useNavigate();

useEffect(() => {
    if(token) {
      let decoded: any = jwtDecode(token);

      // Extract expiration date from the JWT payload
      let expirationDate: Date | null = null;
      if (decoded.exp) {
        expirationDate = new Date(decoded.exp * 1000); // Convert seconds to milliseconds
      }

      if (decoded !== null && decoded.userData !== null && typeof decoded.userData == 'string') {
        const userData: any = JSON.parse(decoded.userData);
        decoded.userData = userData;
      }

      dispatch({type: "LOG_IN", loginData: {authToken: token, payload: decoded, expirationDate: expirationDate}, });
      navigate("/", {replace: true});
    }
    else {
      enqueueSnackbar("Login Failed", {variant: "error"});
    }
    
  }, [token])

  return (<Box> </Box>)

};
export default Redirection;
