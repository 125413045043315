import React, { useEffect, useRef } from "react";

export class UIUtil {
  static getChatQuestions() {
    return [
      {
        question:
          "Key UK architectural regulations impact on project planning?",
      },
      {
        question:
          "How do UK Building Regulations shape construction for architects?",
      },
      { question: "Impact of the Town and Country Planning Act on projects?" },
      {
        question:
          "Regulations for conservation areas and listed buildings for architects?",
      },
      {
        question:
          "Disability Discrimination Act influence on architects in accessibility design?",
      },
      {
        question:
          "UK laws addressing sustainability in architecture and architects' obligations?",
      },
    ];
  }
}
