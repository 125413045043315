import { Button, useTheme } from "@mui/material";
import {
  CheckCircle,
  CheckCircleOutline,
  Storefront,
} from "@mui/icons-material";
import { Box, Container, Typography, useMediaQuery } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { SubscriptionPlanApi } from "../../service/swagger/apis/subscription-plan-api";
import { SubscriptionPlanDto } from "../../service/swagger";
import { useAppStore } from "../../store";

const PaymentSuccess = () => {
  const [state, dispatch] = useAppStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const location = useLocation();
  const subscriptionId = new URLSearchParams(location.search).get("subscriptionPlanId");
  const [plan, setPlan] = useState<SubscriptionPlanDto | null>(null);

  useEffect(() => {
    async function fetchPlan() {
      try {
        const response = (await new SubscriptionPlanApi().apiSubscriptionPlansIdGet(subscriptionId!)).data;
        setPlan(response);

      } catch (e: any) {
        console.log(e);
      }
    }

    if (subscriptionId) {
      fetchPlan();
    }
  }, [subscriptionId]);

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ mr: isMobile ? 0 : 40, ml: isMobile ? 0 : 10, mb: isMobile ? 10 : 0 }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{ mb: 2 }}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "36px",
                height: "36px",
                borderRadius: "50px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#ffffff",
                mr: 2,
              }}
            >
              <Storefront fontSize="medium" />
            </Box>
            <Typography fontFamily="Medium" variant="body1" color="#5A5A5A">
              {`${state.loginData.payload.userData.email}`}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Typography fontFamily="medium" variant="h6" color="#808080">
              {`Subscribe to ${plan?.title} Plan`}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography
                fontFamily="Semibold"
                fontWeight="600"
                fontSize="40px"
                color="black"
                sx={{
                  mr: 1, // Add margin to separate US$0 from the following text
                }}
              >
                £{`${plan?.price}`}
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  fontFamily="Semibold"
                  fontWeight="600"
                  fontSize="12px"
                  color="#808080"
                >
                  per
                </Typography>
                <Typography
                  fontFamily="Semibold"
                  fontWeight="600"
                  fontSize="12px"
                  color="#808080"
                >
                  month
                </Typography>
              </Box>
            </Box>
            <Typography
              fontFamily="Semibold"
              fontWeight="600"
              fontSize="12px"
              color="#808080"
              sx={{ mb: 10 }}
            >
              {`This includes ${plan?.noOfSearches} searches`}
            </Typography>
            <img
              alt="ArcGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: isMobile ? "75px" : "200px",
                height: isMobile ? "75px" : "200px",
              }}
            />
            {isMobile ? (
              <Box />
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                sx={{ mt: isMobile ? 5 : 20 }}
              >
                <Typography
                  fontFamily="Regular"
                  variant="body2"
                  color="#5A5A5A"
                >
                  Powered by{" "}
                  <span style={{ fontFamily: "Bold" }}>KunarchGPT</span>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        {/* Divider Line */}
        {isMobile ? (
          <Box
            position="absolute"
            top="calc(50% - 1px)" // Adjust the position as needed
            width="100%"
            height="2px"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Shadow effect
            }}
          />
        ) : (
          <Box
            position="absolute"
            left="calc(50% - 1px)" // Adjust the position as needed
            height="100%"
            width="2px"
            sx={{
              backgroundColor: "rgba(0, 0, 0, 0.1)", // Adjust the color and opacity as needed
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Shadow effect
            }}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          sx={{ ml: isMobile ? 0 : 40, mt: isMobile ? 5 : 0 }}
        >
          <CheckCircleOutline
            sx={{
              fontSize: isMobile ? "75px" : "100px",
              color: "green",
              mb: 2,
            }}
          />
          <Typography
            fontFamily="Medium"
            variant="h6"
            color="#808080"
            sx={{ mb: 1 }}
          >
            {"Thanks for subscribing!"}
          </Typography>
          <Typography
            fontFamily="regular"
            variant="body2"
            color="#5A5A5A"
            textAlign="center"
            whiteSpace="pre-line"
            sx={{ mb: 1 }}
          >
            {isMobile
              ? "A payment to app.kunarchgpt.uk will appear on \nyour statement."
              : "A payment to app.kunarchgpt.uk will appear on your statement."}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width={isMobile ? "350px" : "450px"}
            height="75px"
            sx={{
              backgroundColor: "#ededed",
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              m: 2,
              p: 2,
            }}
          >
            <Typography fontFamily="Medium" variant="body2" color="#808080">
              {"KUNARCHGPT.CO.UK"}
            </Typography>
            <Typography fontFamily="Medium" variant="body2" color="#808080">
              {`£${plan?.price}`}
            </Typography>
          </Box>
          <Button
            onClick={() => navigate("/")}
            disableRipple
            disableFocusRipple
            disableTouchRipple
            sx={{
              backgroundColor: "green",
              "&:hover": {
                backgroundColor: "limegreen", // Change background color on hover
                "& .MuiTypography-root": {
                  color: "#fff", // Change text color on hover
                },
              },
            }}
          >
            <Typography
              fontFamily="Regular"
              fontSize="18px"
              textTransform="none"
              color="#ffffff"
            >
              Return To Homepage
            </Typography>
          </Button>
          {!isMobile ? (
              <Box />
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                sx={{ mt: isMobile ? 3 : 20 }}
              >
                <Typography
                  fontFamily="Regular"
                  variant="body2"
                  color="#5A5A5A"
                >
                  Powered by{" "}
                  <span style={{ fontFamily: "Bold" }}>KunarchGPT</span>
                </Typography>
              </Box>
            )}
        </Box>
      </Box>
    </Container>
  );
};

export default PaymentSuccess;
