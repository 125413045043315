import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const PaymentFailed = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src="/assets/images/credit-card.png"
          alt="credit-card"
          style={{
            width: isMobile ? "150px" : "200px",
            height: isMobile ? "150px" : "200px",
          }}
        />
        <Typography
          fontFamily="Medium"
          variant={isMobile ? "h5" : "h4"}
          sx={{ mt: 3 }}
        >
          {"Payment Failed"}
        </Typography>
        <Typography
          fontFamily="Regular"
          variant={isMobile ? "body1" : "h5"}
          color="#808080"
          whiteSpace="pre-line"
          textAlign="center"
          sx={{ mt: 3 }}
        >
          {isMobile
            ? "The Payment was unsuccessful due to an \nabnormality. Please try again later."
            : "The Payment was unsuccessful due to an abnormality. \nPlease try again later."}
        </Typography>
        <Button
          onClick={() => navigate("/")}
          disableRipple
          disableFocusRipple
          disableTouchRipple
          sx={{
            backgroundColor: "#f44336",
            "&:hover": {
              backgroundColor: "#f56459", // Change background color on hover
              "& .MuiTypography-root": {
                color: "#fff", // Change text color on hover
              },
            },
            mt: 5
          }}
        >
          <Typography
            fontFamily="Regular"
            fontSize="18px"
            textTransform="none"
            color="#ffffff"
          >
            Return To Homepage
          </Typography>
        </Button>
      </Box>
    </Container>
  );
};

export default PaymentFailed;
