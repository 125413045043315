import { Route, Routes } from "react-router-dom";
// import APIKeyManagement from '../views/api-keys/APIKeyManagement';
// import Faqs from '../views/info/Faqs';
import Profile from "../views/settings/Profile";
import Settings from "../views/settings/Settings";
import { useAppStore } from "../store";
import { useState } from "react";
import { GLOBAL_ROLES } from "../utils";
import React from "react";
import LandingPage from "../views/home/LandingPage";
import Plans from "../views/payment/Plans";
import NotFoundView from "../views/NotFoundView";
// import Chat from "../views/chat/Chat";
import ArcChat from "../views/chat/ArcChat";
import Terms from "../views/info/Terms";
import PaymentSuccess from "../views/payment/PaymentSuccessful";
import PaymentFailed from "../views/payment/PaymentFailed";

const PrivateRoutes = () => {
  const [state, dispatch] = useAppStore();

  const AppRoutes = [
    <Route key="home" path="/" element={<LandingPage />} />,
    <Route key="arcgpt" path="/arcgpt" element={<LandingPage />} />,
    // <Route
    //   key="profile"
    //   path="/settings/profile/:userId"
    //   element={<Profile />}
    // />,
    // <Route key="settings" path="/settings" element={<Settings />} />,
    <Route key="plans" path="/plans" element={<Plans />} />,
    <Route key="chat" path="/chat" element={<ArcChat />} />,
    <Route key="terms" path="/terms-conditions" element={<Terms />} />,
    <Route key="payment-success" path="/payment-success" element={<PaymentSuccess />} />,
    <Route key="payment-failed" path="/payment-failed" element={<PaymentFailed />} />,
    <Route key="notfound" path="*" element={<NotFoundView />} />,
  ];

  return <Routes>{AppRoutes}</Routes>;
};

export default PrivateRoutes;
