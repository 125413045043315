import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppForm } from "../../utils/form";
import { SyntheticEvent, useCallback, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthenticationApi } from "../../service/swagger/apis/authentication-api";
import axios from "axios";

const VALIDATION_FORM_RESET = {
  newPassword: {
    presence: true,
    length: {
      minimum: 8,
      message: "must be minimum 8 characters",
    },
    format: {
      pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
      message: "must include numbers and special characters",
    },
  },
  confirmPassword: {
    presence: true,
    equality: {
      attribute: "newPassword",
      message: "and New Password do not match.",
    },
  },
};

interface FormStateValues {
  newPassword: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [formState, setFormState, onFieldChange, fieldGetError, fieldHasError] =
    useAppForm({
      validationSchema: VALIDATION_FORM_RESET,
      initialValues: {
        newPassword: "",
        confirmPassword: "",
      } as FormStateValues,
    });
  const values = formState.values as FormStateValues;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const token = new URLSearchParams(location.search).get("token");
  const email = new URLSearchParams(location.search).get("email");
  const [loading, setLoading] = useState<boolean>(false);

  const handleVerifyCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value } = event.target;

    // Remove any non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Limit the length to 6 characters
    const limitedValue = numericValue.slice(0, 6);

    // Update the input value to only contain numeric characters and be at most 6 characters
    event.target.value = limitedValue;

    // Call the original onFieldChange to update the form state
    onFieldChange(event);
  };

  const handleShowPasswordClick = useCallback(() => {
    setShowPassword((oldValue) => !oldValue);
  }, []);

  const handleShowConfirmPasswordClick = useCallback(() => {
    setConfirmShowPassword((oldValue) => !oldValue);
  }, []);

  const handlePasswordReset = useCallback(
    async (event: SyntheticEvent) => {
      event.preventDefault();
      try {
        setLoading(true);
        const response =
          await new AuthenticationApi().apiAuthCompleteForgotPasswordPost({
            token: token!,
            email: email!,
            newPassword: values.newPassword,
            confirmNewPassword: values.confirmPassword,
          });

        if (axios.isAxiosError(response)) {
          enqueueSnackbar(response.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          enqueueSnackbar("You have successfully reset your password", {
            variant: "success",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
          navigate("/", { replace: true });
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [values, enqueueSnackbar]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start", // Push content to the top and bottom
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fafafa",
        }}
      >
        <Dialog open={true} fullWidth maxWidth={isMobile ? "sm" : "lg"}>
          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* Circular progress */}
              <CircularProgress sx={{ color: "#ffffff" }} />
            </div>
          )}
          <Paper sx={{ backgroundColor: "transparent", overflow: "hidden" }}>
            <DialogContent sx={{ display: "flex", alignItems: "center" }}>
              {isMobile ? (
                <Box />
              ) : (
                <Box sx={{ width: "50%", pr: 2 }}>
                  {/* Image on the left */}
                  <img
                    src="/assets/images/authbg.jpg"
                    alt="Authentication Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              )}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ width: isMobile ? "100%" : "50%" }}
              >
                {/* Login form on the right */}
                <Card elevation={0}>
                  <CardHeader
                    title="RESET YOUR PASSWORD"
                    titleTypographyProps={{
                      fontSize: isMobile ? "18px" : "24px",
                      textAlign: "center",
                    }}
                  />
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      flexDirection="column"
                      sx={{ width: "100%", mt: 2 }}
                    >
                      <form onSubmit={handlePasswordReset}>
                        <TextField
                          name="newPassword"
                          label="NEW PASSWORD"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type={showPassword ? "text" : "password"}
                          value={values.newPassword}
                          onChange={onFieldChange}
                          error={fieldHasError("newPassword")}
                          helperText={fieldGetError("newPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    showPassword
                                      ? "Hide password" ?? ``
                                      : "Show password"
                                  }
                                >
                                  <IconButton
                                    onClick={handleShowPasswordClick}
                                    onMouseDown={(event) =>
                                      event.preventDefault
                                    }
                                  >
                                    {showPassword ? (
                                      <VisibilityOff
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <TextField
                          name="confirmPassword"
                          label="CONFIRM PASSWORD"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          type={showConfirmPassword ? "text" : "password"}
                          value={values.confirmPassword}
                          onChange={onFieldChange}
                          error={fieldHasError("confirmPassword")}
                          helperText={fieldGetError("confirmPassword")}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={
                                    showConfirmPassword
                                      ? "Hide password" ?? ``
                                      : "Show password"
                                  }
                                >
                                  <IconButton
                                    onClick={handleShowConfirmPasswordClick}
                                    onMouseDown={(event) =>
                                      event.preventDefault
                                    }
                                  >
                                    {showConfirmPassword ? (
                                      <VisibilityOff
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    ) : (
                                      <Visibility
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    )}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </form>
                      <LoadingButton
                        fullWidth
                        disabled={!formState.isValid}
                        loading={loading}
                        onClick={handlePasswordReset}
                        sx={{
                          border: "1px solid #38618c",
                          mt: 3,
                          color: "white",
                          "&:hover": {
                            backgroundColor: "#38618c", // Brighter red on hover
                            "& .MuiTypography-root": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        <Typography color="#38618c" fontFamily="Regular">
                          RESET PASSWORD
                        </Typography>
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </DialogContent>
          </Paper>
        </Dialog>
      </Box>
    </Container>
  );
};

export default ResetPassword;
