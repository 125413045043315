import React, { PropsWithChildren, useEffect, useRef } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import {
  Stack,
  Box,
  Typography,
  Link,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PrivateNavbar from "./Navbar/PrivateNavbar";
import { Mail, LinkedIn, Instagram, Settings } from "@mui/icons-material";
import { useAppStore } from "../store";
import ResetPassword from "../views/auth/ResetPassword";
import LandingPage from "../views/home/LandingPage";
import Plans from "../views/payment/Plans";
import Profile from "../views/settings/Profile";
import ParticlesComponent from "../components/ParticlesComponent";
import { localStorageGet } from "../utils";

const PrivateLayout: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useAppStore();
  const tokenExpiredRef = useRef(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Define your routes
  const routes = [
    { path: "/", element: <LandingPage /> },
    { path: "/arcgpt", element: <LandingPage /> },
    { path: "/settings/profile/:userId", element: <Profile /> },
    { path: "/settings", element: <Settings /> },
    // { path: "/chat", element: <Plans /> },
    // Add more routes as needed
  ];

  // Check if the current route matches any of the specified routes
  const matchedRoute = useRoutes(routes);

  useEffect(() => {
    const checkTokenExpiration = () => {
      console.log("Checking token expiration");
      const tokenExpiry = localStorageGet("tokenExpiry");
      if (tokenExpiry && new Date(tokenExpiry) < new Date()) {
        dispatch({ type: "LOG_OUT" });
        navigate("/", { replace: true });
        tokenExpiredRef.current = true;
      }
    };

    // Check token expiration when the component mounts
    checkTokenExpiration();

    // Check token expiration every 3 seconds
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 60 * 1000);

    // Cleanup: clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [dispatch, navigate, state.tokenExpiry]); // Only include dependencies needed inside the effect

  return (
    <Stack direction="column" style={{ minHeight: 0, flex: 1 }}>
      <Box
        flexGrow={1}
        position="relative"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -10,
        }}
      >
        <ParticlesComponent />
        {matchedRoute && <PrivateNavbar />}
        <Box sx={{ position: "relative" }}>{children}</Box>
        {matchedRoute && (
          <Box
            sx={{
              backgroundColor: "black",
              color: "white",
              textAlign: "center",
              p: 2,
              position: "relative",
            }}
          >
            <img
              alt="ArcGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: isMobile ? "50px" : "100px",
                height: isMobile ? "50px" : "100px",
              }}
            />
            <Typography fontFamily="Regular" variant="body2" mb={1}>
              {"© ArchGPT UK"}
            </Typography>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              mb={1}
            >
              <Mail
                onClick={() =>
                  window.open("mailto:sales@kunarchgpt.uk", "_blank")
                }
                fontSize="small"
                sx={{ mr: 1 }}
              />
              {/* <LinkedIn
                onClick={() =>
                  window.open(
                    "http://linkedin.com/in/faran-lodhi-64a645a2",
                    "_blank"
                  )
                }
                fontSize="small"
                sx={{ mr: 1 }}
              />
              <Instagram
                onClick={() =>
                  window.open("https://www.instagram.com/flaaa9667", "_blank")
                }
                fontSize="small"
              /> */}
            </Box>
            <Link href="/terms-conditions" underline="none" color="white">
              <Typography fontFamily="Regular" variant="body2">
                {"Terms & Conditions"}
              </Typography>
            </Link>
          </Box>
        )}
      </Box>
    </Stack>
  );
};

export default PrivateLayout;
