import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { enqueueSnackbar } from 'notistack';
import { useAppStore } from '../store';
import { useNavigate } from 'react-router-dom';
import { AuthenticationApi } from "../service/swagger/apis/authentication-api";

export default class ArcGPTService {
  public async login(email: string, password: string): Promise<any> {
    try {
      let d = await new AuthenticationApi().apiAuthLoginPost({
        email: email,
        password: password,
      });
      if (axios.isAxiosError(d)) {
        enqueueSnackbar(d.response?.data.errors[0], {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
        });
      }
      console.log('Result: ', d.request);

      let decoded: any = d.data.token ? jwtDecode(d.data.token) : {};

      // Extract expiration date from the JWT payload
      let expirationDate: Date | null = null;
      if (decoded.exp) {
        expirationDate = new Date(decoded.exp * 1000); // Convert seconds to milliseconds
      }

      if (decoded !== null && decoded.userData !== null && typeof decoded.userData == 'string') {
        const userData: any = JSON.parse(decoded.userData);
        decoded.userData = userData;
      }

      return {
        authToken: d.data.token,
        payload: decoded,
        expirationDate: expirationDate,
      };
    } catch (error: any) {
      console.error('Error during login:', error);
      return Promise.reject(error.response?.data || 'Internal Server Error');
    }
  }
}
