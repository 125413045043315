import { PaletteOptions, SimplePaletteColorOptions } from '@mui/material/styles';

const COLOR_PRIMARY: SimplePaletteColorOptions = {
    main: '#343541',
    contrastText: '#ffffff'
};

const COLOR_SECONDARY: SimplePaletteColorOptions = {
    main: '#444654',
    contrastText: '#ffffff'
};

export const PALETTE_COLORS: Partial<PaletteOptions> = {
    primary: COLOR_PRIMARY,
    secondary: COLOR_SECONDARY
};