import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

/**
 * "Not Found" aka "Error 404" view
 */
const NotFoundView = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  document.title = "404: This page could not be found";
  return (
    <div
      style={{
        backgroundColor: "black",
        color: "white",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h2 style={{ fontFamily: "Regular", margin: "10px" }}>
        404 | This page could not be found.
      </h2>
      <p style={{ fontFamily: "Regular", textAlign: "center", margin: "10px" }}>
        Sorry, the page you are looking for might be in another castle.
      </p>
      {/* You can add more content or styling based on your application's design */}
    </div>
  );
};

export default NotFoundView;
