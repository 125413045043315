import {
  AppBar,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useAppStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
  ArrowBackIos,
  ArrowUpward,
  Check,
  FileCopy,
  Refresh,
  Send,
  VolumeDown,
  VolumeUp,
} from "@mui/icons-material";
import { PuffLoader, PulseLoader } from "react-spinners";
import axios from "axios";
import { localStorageSet, shuffleArray } from "../../utils";
import { useTranslation } from "react-i18next";
import { UIUtil } from "../../utils/ui-util";
import { AuthenticationApi } from "../../service/swagger";
import { enqueueSnackbar } from "notistack";
import { ChatApi } from "../../service/swagger/apis/chat-api";
import React from "react";

const ArcChat = () => {
  const [state, dispatch] = useAppStore();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loggedInUser] = useState(state.loginData.payload.userData ?? "");
  const { t, i18n } = useTranslation(["text"]);
  const navigate = useNavigate();
  const [query, setQuery] = useState<string>("");
  const [chat, setChat] = useState<
    { role: string; text: string; animation?: boolean }[]
  >([]);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isBoxVisible, setIsBoxVisible] = useState<boolean>(false);
  const shuffledQuestions = useRef(shuffleArray(UIUtil.getChatQuestions()));
  const [hasFadedIn, setHasFadedIn] = useState(false);
  const [copyStatus, setCopyStatus] = useState<boolean[]>([]);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const speechRef = useRef<SpeechSynthesisUtterance | null>(null); // Specify the type
  const [loading, setLoading] = useState<boolean>(false);
  const [searchedLeft, setSearchesLeft] = useState<number>(0);

  useEffect(() => {
    async function fetchNoSearches() {
      try {
        setLoading(true);
        const response = await new ChatApi().apiChatUserPromptsLeftPost(
          state.loginData.payload.userData.id
        );
        if (axios.isAxiosError(response)) {
          navigate("/plans");
          enqueueSnackbar(response.response?.data.errors[0], {
            variant: "error",
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
          });
        } else {
          if (response.data === 0) {
            setSearchesLeft(response.data);
            console.log(response.data);
            navigate("/plans");
            enqueueSnackbar(
              "You are out of searches. Please upgrade your subscription plan.",
              {
                variant: "error",
                anchorOrigin: { vertical: "bottom", horizontal: "center" },
              }
            );
          } else {
            setSearchesLeft(response.data);
            console.log(response.data);
          }
        }
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }

    fetchNoSearches();
  }, []);

  useEffect(() => {
    setCopyStatus(chat.map(() => false));
  }, [chat]);

  const handleCopyClick = (index: number, text: string) => {
    navigator.clipboard.writeText(text);

    const newCopyStatus = [...copyStatus];
    newCopyStatus[index] = true;
    setCopyStatus(newCopyStatus);

    setTimeout(() => {
      const revertedCopyStatus = [...copyStatus];
      revertedCopyStatus[index] = false;
      setCopyStatus(revertedCopyStatus);
    }, 2000);
  };

  // Effect to trigger the fade-in animation after the component mounts
  useEffect(() => {
    setHasFadedIn(true);
  }, []);

  const avatars: { [key: string]: string } = {
    user: "/assets/man.png", // Replace with the actual path to the user avatar image
    bot: "/assets/images/ArcGPT-Logo2.png", // Replace with the actual path to the bot avatar image
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const userMessage = query;
    setQuery("");
    setShowLoader(true);

    try {
      const userMessageObj = {
        role: "user",
        text: userMessage,
        animation: true,
      };
      console.log("-------------------" + chat);

      const queryLimit = await new ChatApi().apiChatUserPromptsLeftPost(
        loggedInUser.id
      );
      if (axios.isAxiosError(queryLimit)) {
        setShowLoader(false);
        enqueueSnackbar(queryLimit.response?.data.errors[0], {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      } else {
        if (queryLimit.data > 0) {
          setChat((prevChat) => [...prevChat, userMessageObj]);
          const response = (await new ChatApi().apiChatGetAnswerPost(query))
            .data;
          const lines = response.split("\n");
          const botMessage = { role: "bot", text: lines, animation: true };
          setChat((prevChat) => [
            ...prevChat,
            {
              role: botMessage.role,
              text: Array.isArray(botMessage.text)
                ? botMessage.text.join(" ")
                : botMessage.text,
              animation: botMessage.animation,
            },
          ]);

          const queryLimit = await new ChatApi().apiChatUserPromptsLeftPost(
            loggedInUser.id
          );

          setSearchesLeft(queryLimit.data);
        } else {
          setSearchesLeft(queryLimit.data);
          // navigate("/plans");
          enqueueSnackbar(
            "You are out of searches. Please upgrade your subscription plan.",
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleInstantSubmit = async (query: string) => {
    const userMessage = query;
    setQuery("");
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setShowLoader(true);

    try {
      const userMessageObj = {
        role: "user",
        text: userMessage,
        animation: true,
      };

      const queryLimit = await new ChatApi().apiChatUserPromptsLeftPost(
        loggedInUser.id
      );
      if (axios.isAxiosError(queryLimit)) {
        setShowLoader(false);
        enqueueSnackbar(queryLimit.response?.data.errors[0], {
          variant: "error",
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
        });
      } else {
        if (queryLimit.data > 0) {
          setChat((prevChat) => [...prevChat, userMessageObj]);
          const response = (await new ChatApi().apiChatGetAnswerPost(query))
            .data;
          const lines = response.split("\n");
          const botMessage = { role: "bot", text: lines, animation: true };
          setChat((prevChat) => [
            ...prevChat,
            {
              role: botMessage.role,
              text: Array.isArray(botMessage.text)
                ? botMessage.text.join(" ")
                : botMessage.text,
              animation: botMessage.animation,
            },
          ]);

          const queryLimit = await new ChatApi().apiChatUserPromptsLeftPost(
            loggedInUser.id
          );

          setSearchesLeft(queryLimit.data);
        } else {
          setSearchesLeft(queryLimit.data);
          // navigate("/plans");
          enqueueSnackbar(
            "You are out of searches. Please upgrade your subscription plan.",
            {
              variant: "error",
              anchorOrigin: { vertical: "bottom", horizontal: "center" },
            }
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleRefreshClick = () => {
    console.log("I have been clicked");
    // Find the index of the last bot message
    const lastBotIndex = chat.map((msg) => msg.role).lastIndexOf("bot");
    console.log(lastBotIndex);
    if (lastBotIndex !== -1) {
      // Remove the last bot message
      // const updatedChat = [
      //   ...chat.slice(0, lastBotIndex),
      //   ...chat.slice(lastBotIndex + 1),
      // ];
      // setChat(updatedChat);

      // Set the last user message in the query state
      const lastUserMessage = chat[lastBotIndex - 1]; // Assuming the user message precedes the bot message
      console.log(lastUserMessage);
      handleInstantSubmit(lastUserMessage.text);
    }
  };

  const handleVolumeUpClick = (message: string) => {
    if (!isSpeaking) {
      const speech = new SpeechSynthesisUtterance(message);
      speechRef.current = speech;
      window.speechSynthesis.speak(speech);
      setIsSpeaking(true);
    } else {
      if (speechRef.current) {
        window.speechSynthesis.cancel();
        setIsSpeaking(false);
      }
    }
  };

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  useEffect(() => {
    // After a delay, remove the animation flag from the last message
    const timeout = setTimeout(() => {
      setChat((prevChat) =>
        prevChat.map((message, index) => ({
          ...message,
          animation: index === prevChat.length - 1 ? false : message.animation,
        }))
      );
    }, 300);

    return () => clearTimeout(timeout);
  }, [chat]);

  useEffect(() => {
    // Set the box to be visible after a delay when the component mounts
    const delay = setTimeout(() => {
      setIsBoxVisible(true);
    }, 300); // Adjust the delay as needed

    return () => clearTimeout(delay);
  }, []);

  const listRef = useRef<HTMLUListElement>(null);
  const [shouldScroll, setShouldScroll] = useState(true);

  useEffect(() => {
    if (listRef.current && shouldScroll) {
      // Scroll the last message into view
      const lastMessage = listRef.current.lastChild as HTMLElement;
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }
  }, [chat, shouldScroll]);

  const handleWheel = () => {
    setShouldScroll(false);
  };

  const handleScroll = () => {
    if (
      listRef.current &&
      listRef.current.scrollTop + listRef.current.clientHeight ===
        listRef.current.scrollHeight
    ) {
      setShouldScroll(true);
    }
  };

  useEffect(() => {
    console.log("searches left", searchedLeft);
  }, [searchedLeft]);

  return loading ? (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Circular progress */}
      <CircularProgress sx={{ color: "#ffffff" }} />
    </div>
  ) : (
    <React.Fragment>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "black", height: "48px" }}
      >
        <Toolbar variant="dense">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="back"
            onClick={() => navigate("/")}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography
            fontFamily="Semibold"
            variant="h6"
            color="white"
            sx={{ ml: 2 }}
          >
            ARCH GPT
          </Typography>
          <Typography
            variant="h6"
            align="right" // Align text to the right
            fontFamily="Medium"
            sx={{ flexGrow: 1, color: "white" }} // Remove marginLeft: "auto"
          >
            {`${searchedLeft} searches left`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth={false}
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between", // Updated to space-between
          padding: 0,
          margin: 0,
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        ></Box>
        {chat.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <img
              alt="KunarchGPT"
              src="/assets/images/ArcGPT-Logo2.png"
              style={{
                width: "60px",
                height: "60px",
                border: "1px solid white",
              }}
            />
            <Typography
              fontFamily="Bold"
              fontWeight="bold"
              fontSize="22px"
              color="white"
              sx={{ mt: 2 }}
            >
              {"How can I help you today?"}
            </Typography>
          </Box>
        )}
        {chat.length === 0 ? null : (
          <Stack
            direction="column"
            flex={1}
            sx={{
              overflowY: "auto",
              scrollbarWidth: "thin",
              scrollbarColor: "#ffffff #000000",
              padding: 0,
              margin: 0,
            }}
          >
            <List
              ref={listRef}
              sx={{
                scrollbarWidth: "thin",
                scrollbarColor: "#ffffff #000000",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                marginTop: isMobile ? 1 : 5,
              }}
              onScroll={handleScroll}
              onWheel={handleWheel}
            >
              {chat.map((message, index) => (
                <ListItem
                  key={index}
                  style={{
                    opacity: message.animation ? 0 : 1,
                    transition: "opacity 0.3s ease",
                    display: "flex",
                    flexDirection: "column", // Display components in a column
                    alignItems: "flex-start",
                    width: "100%",
                    // margin: 5,
                  }}
                  onTransitionEnd={() => {
                    if (message.animation) {
                      setChat((prevChat) =>
                        prevChat.map((prevMessage, i) =>
                          i === index
                            ? { ...prevMessage, animation: false }
                            : prevMessage
                        )
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      padding: isMobile ? "5px" : "0px 20px",
                      wordWrap: "break-word",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {message.role !== "bot" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Tooltip
                          arrow
                          title={loggedInUser.userName ?? "Anonymous"}
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                backgroundColor: "white",
                                color: "black",
                              },
                              "& .MuiTooltip-arrow": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <Avatar
                            sx={{
                              width: "30px",
                              height: "30px",
                              marginTop: "5px",
                              bgcolor: "white",
                            }}
                            variant="circular"
                          >
                            <Typography
                              fontFamily="Medium"
                              fontWeight="500"
                              fontSize="24px"
                              color="black"
                            >
                              {loggedInUser.userName
                                .split(" ")
                                .slice(0, 2)
                                .map((word: string) => word[0].toUpperCase())
                                .join("")}
                            </Typography>
                          </Avatar>
                        </Tooltip>
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          marginLeft="10px"
                          color="#ffffff"
                        >
                          You
                        </Typography>
                      </div>
                    )}
                    {message.role === "bot" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "20px",
                        }}
                      >
                        <Avatar
                          sx={{
                            width: "30px",
                            height: "30px",
                            marginTop: "5px",
                            border: "1px solid white",
                          }}
                          src={avatars[message.role]}
                          variant="circular"
                        />
                        <Typography
                          variant="body2"
                          fontWeight="bold"
                          marginLeft="10px"
                          color="#ffffff"
                        >
                          KunarchGPT
                        </Typography>
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginLeft: isMobile ? "50px" : "70px", // Adjust the margin to separate the avatar and text
                      paddingRight: isMobile ? "5px" : 0,
                    }}
                  >
                    <ListItemText
                      primary={message.text}
                      primaryTypographyProps={{
                        fontFamily: "Regular",
                        fontWeight: "400",
                      }}
                      style={{
                        color: "white",
                        fontFamily: "Regular",
                        fontWeight: message.role === "bot" ? "bold" : "normal",
                      }}
                    />

                    <div style={{ display: "flex", marginTop: "10px" }}>
                      {copyStatus[index] ? (
                        <Check
                          fontSize="small"
                          style={{ color: "white", marginRight: "5px" }}
                        />
                      ) : (
                        <Tooltip
                          arrow
                          title="Copy"
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                backgroundColor: "white",
                                color: "black",
                              },
                              "& .MuiTooltip-arrow": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <FileCopy
                            fontSize="small"
                            style={{ color: "white", marginRight: "5px" }}
                            onClick={() => handleCopyClick(index, message.text)}
                          />
                        </Tooltip>
                      )}
                      {message.role === "bot" && index === chat.length - 1 && (
                        <Tooltip
                          arrow
                          title="Regenerate"
                          PopperProps={{
                            modifiers: [
                              {
                                name: "offset",
                                options: {
                                  offset: [0, 0],
                                },
                              },
                            ],
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                backgroundColor: "white",
                                color: "black",
                              },
                              "& .MuiTooltip-arrow": {
                                color: "white",
                              },
                            },
                          }}
                        >
                          <Refresh
                            fontSize="small"
                            style={{ color: "white", marginRight: "5px" }}
                            onClick={handleRefreshClick}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        arrow
                        title={isSpeaking ? "Pause audio" : "Read aloud"}
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, 0],
                              },
                            },
                          ],
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              backgroundColor: "white",
                              color: "black",
                            },
                            "& .MuiTooltip-arrow": {
                              color: "white",
                            },
                          },
                        }}
                      >
                        {isSpeaking ? (
                          <VolumeDown
                            fontSize="small"
                            style={{ color: "white" }}
                            onClick={() => handleVolumeUpClick(message.text)}
                          />
                        ) : (
                          <VolumeUp
                            fontSize="small"
                            style={{ color: "white" }}
                            onClick={() => handleVolumeUpClick(message.text)}
                          />
                        )}
                      </Tooltip>
                    </div>
                  </div>
                </ListItem>
              ))}

              {showLoader && (
                <div
                  style={{
                    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)",
                    padding: isMobile ? "8px" : "20px",
                    wordWrap: "break-word",
                    width: "100%",
                  }}
                >
                  <ListItem>
                    <Avatar
                      src={avatars["bot"]}
                      variant="circular"
                      sx={{
                        width: "30px",
                        height: "30px",
                        marginRight: "10px",
                        border: "1px solid white",
                      }}
                    />
                    <PuffLoader color="white" size={20} />
                  </ListItem>
                </div>
              )}
            </List>
          </Stack>
        )}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          marginTop="10px"
          marginBottom="10px"
          style={{ bottom: 0, width: "100%" }}
        >
          {chat.length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                alignItems: "center",
                mb: 2,
                m: isMobile ? 1 : 0,
              }}
            >
              {shuffledQuestions.current
                .slice(0, isMobile ? 2 : 3)
                .map((questionObject, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      width: isMobile ? "100%" : "300px",
                      border: "1px solid grey",
                      borderRadius: "10px",
                      p: isMobile ? 2 : 5,
                      mr: isMobile ? 0 : 1,
                      mb: 1,
                      position: "relative",
                      opacity: hasFadedIn ? 1 : 0,
                      transition: "opacity 0.6s",
                      "&:hover": {
                        opacity: 0.5,
                      },
                      "&:hover .arrow-icon": {
                        opacity: 1,
                      },
                    }}
                  >
                    <Typography
                      fontFamily="Bold"
                      fontSize={isMobile ? "10px" : "14px"}
                      fontWeight="bold"
                      color="white"
                    >
                      {questionObject.question}
                    </Typography>
                    <Box
                      className="arrow-icon"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        opacity: 0,
                        transition: "opacity 0.3s",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        width="30px"
                        height="30px"
                        sx={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <IconButton
                          onClick={() =>
                            handleInstantSubmit(questionObject.question)
                          }
                          type="submit"
                        >
                          <ArrowUpward sx={{ color: "black" }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                ))}
            </Box>
          )}

          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <TextField
              value={query}
              onChange={handleQueryChange}
              autoComplete="off"
              placeholder={"Message KunarchGPT..."}
              sx={{
                width: isMobile ? "100%" : "50%",
                border: "1px solid lightgray",
                borderRadius: "20px",
                "& .MuiInputLabel-root": {
                  display: "none",
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                  },
                  "&:hover fieldset": {
                    border: "1px solid grey",
                    borderRadius: "20px",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid grey",
                    borderRadius: "20px",
                  },
                  "& input": {
                    color: "white",
                  },
                  "& input::placeholder": {
                    color: "white",
                  },
                  "& input:-webkit-autofill": {
                    "-webkit-text-fill-color": "black",
                    "-webkit-box-shadow": "0 0 0px 1000px #444654 inset",
                  },
                },
                "& input::-webkit-input-placeholder": {
                  color: "black",
                },
                "& input::-moz-placeholder": {
                  color: "black",
                },
                "& input:-ms-input-placeholder": {
                  color: "black",
                },
                "& input::-ms-input-placeholder": {
                  color: "black",
                },
                "& input::placeholder": {
                  color: "black",
                },
              }}
              disabled={showLoader}
              InputProps={{
                endAdornment: showLoader ? (
                  <PulseLoader color="white" size={10} margin={4} />
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="30px"
                    height="30px"
                    marginLeft={1}
                    sx={{
                      backgroundColor: query === "" ? "black" : "white",
                      borderRadius: "10px",
                    }}
                  >
                    <Tooltip
                      title="Send a Message"
                      placement="top"
                      arrow
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, 14],
                            },
                          },
                        ],
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            backgroundColor: "white",
                            color: "black",
                          },
                          "& .MuiTooltip-arrow": {
                            color: "white",
                          },
                        },
                      }}
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "10px",
                      }}
                    >
                      <IconButton
                        onClick={() => handleSubmit}
                        disabled={query === ""}
                        type="submit"
                      >
                        <ArrowUpward
                          sx={{ color: query === "" ? "white" : "black" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                ),
              }}
              InputLabelProps={{
                shrink: false,
              }}
            />
            <Box mt="10px">
              <Typography
                fontFamily="Medium"
                fontSize="12px"
                fontWeight="500"
                color="#ffffff"
                textAlign="center"
                sx={{ mb: 1 }}
              >
                {
                  "KunarchGPT can make mistakes. Consider checking important information."
                }
              </Typography>
            </Box>
          </form>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default ArcChat;
